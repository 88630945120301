import { gql } from '@apollo/client';

export const IMAGE_VARIANT_FRAGMENT = gql`
  fragment imageVariant on ImageVariant {
    id
    imageId
    path
    url
    width
    height
    size
    crop {
      x
      y
      width
      height
    }
  }
`;
