import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!, $firebase: Object!) {
    login(email: $email, password: $password, firebase: $firebase) @client
  }
`;

export const LOGOUT = gql`
  mutation Logout($firebase: Object!) {
    logout(firebase: $firebase) @client
  }
`;
