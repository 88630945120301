import React, { useMemo } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import { Check, XLg } from 'styles/icons';
import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang,
} from 'types/rawMaterials.types';
import { MultiLanguageString } from 'types/types.types';
import { formatDateTime } from 'utils/dates';
import { displayNullableValue } from 'utils/general';
import { getAvailableStatusUpdates } from 'utils/rawMaterials';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { ActionButtonWrapper, StatusBox, StyledActionBox } from '../styles';

interface Props {
  activeRawMaterial: IRawMaterialMultiLang<MultiLanguageString>;
  handleUpdateRawMaterialStatus: (
    status: IRawMaterialLogAction
  ) => Promise<void>;
  isFormDirty: boolean;
  triggerValidation: UseFormTrigger<IRawMaterialMultiLang<MultiLanguageString>>;
}

const CertificationStatusBlock: React.FC<Props> = ({
  activeRawMaterial,
  handleUpdateRawMaterialStatus,
  isFormDirty,
  triggerValidation,
}) => {
  const availableStatusUpdates: IRawMaterialLogAction[] = useMemo(() => {
    return getAvailableStatusUpdates(activeRawMaterial.latestLogAction);
  }, [activeRawMaterial.latestLogAction]);

  return (
    <StyledActionBox>
      <StatusBox>
        <LabelWithValue label="Status">
          <div>{displayNullableValue(activeRawMaterial?.latestLogAction)}</div>
        </LabelWithValue>
        <LabelWithValue label="Request by">
          <div>{displayNullableValue(activeRawMaterial?.latestLog.userId)}</div>
        </LabelWithValue>
        <LabelWithValue label="Request time">
          <div>
            {activeRawMaterial?.latestLog.createdTimestamp
              ? formatDateTime(activeRawMaterial?.latestLog.createdTimestamp)
              : '-'}
          </div>
        </LabelWithValue>
      </StatusBox>

      <ActionButtonWrapper>
        {availableStatusUpdates.includes('DENIED' as IRawMaterialLogAction) && (
          <Button
            color="error"
            disabled={isFormDirty}
            title={isFormDirty ? 'Save changes first' : 'Deny'}
            variant="contained"
            onClick={() => {
              handleUpdateRawMaterialStatus('DENIED' as IRawMaterialLogAction);
            }}
          >
            <Box mr={1}>
              <XLg size={10} />
            </Box>
            Deny
          </Button>
        )}

        {availableStatusUpdates.includes(
          'APPROVED' as IRawMaterialLogAction
        ) && (
          <Button
            color="success"
            disabled={isFormDirty}
            title={isFormDirty ? 'Save changes first' : 'Approve'}
            variant="contained"
            onClick={async () => {
              // Validate if all fields are filled in before we can approve the MR
              const isFormValid: boolean = await triggerValidation();

              if (isFormValid) {
                handleUpdateRawMaterialStatus(
                  'APPROVED' as IRawMaterialLogAction
                );
              } else {
                toast.error('Please fill in all fields & translations first');
              }
            }}
          >
            <Box mr={1}>
              <Check size={16} />
            </Box>
            Approve
          </Button>
        )}
      </ActionButtonWrapper>
    </StyledActionBox>
  );
};

export default CertificationStatusBlock;
