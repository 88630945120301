import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Production, Signature, User } from 'styles/icons';
import { ImpactClaimKpis, ProductKpis, UserKpis } from 'types/companies.types';
import { StyledContainer } from '../styles';
import StatisticBlock from './StatisticBlock/StatisticBlock';

interface Props {
  impactClaimKpis?: ImpactClaimKpis;
  productKpis?: ProductKpis;
  userKpis?: UserKpis;
}

export const StatisticsWrapper = styled(Box)`
  display: 'flex';
  flex-wrap: 'wrap';
`;

const CompanyStatistics: React.FC<Props> = ({
  impactClaimKpis,
  productKpis,
  userKpis,
}) => {
  return (
    <StyledContainer>
      <Typography gutterBottom fontWeight={700} variant="subtitle1">
        Statistics
      </Typography>
      <StatisticsWrapper>
        {userKpis && (
          <StatisticBlock
            icon={<User size={18} />}
            primaryIndicator={userKpis.total}
            secondaryIndicators={[
              { label: 'Registered', value: userKpis.registered },
              { label: 'Not registered', value: userKpis.notRegistered },
            ]}
            title="users"
          />
        )}

        {productKpis && (
          <StatisticBlock
            icon={<Production size={18} />}
            primaryIndicator={productKpis.total}
            secondaryIndicators={[
              { label: 'Published', value: productKpis.published },
              { label: 'Draft', value: productKpis.drafts },
            ]}
            title="products"
          />
        )}

        {impactClaimKpis && (
          <StatisticBlock
            icon={<Signature size={18} />}
            primaryIndicator={impactClaimKpis.total}
            secondaryIndicators={[
              { label: 'Approved', value: impactClaimKpis.approved },
              { label: 'Requested', value: impactClaimKpis.requested },
            ]}
            title="impact claims"
          />
        )}
      </StatisticsWrapper>
    </StyledContainer>
  );
};

export default CompanyStatistics;
