import React from 'react';
import useAuth from 'components/Auth/hooks/useAuth';
import LoginForm from 'components/Auth/LoginForm/LoginForm';
import { Container, FormContainer, Title } from './styles';

const Login: React.FC = () => {
  const { onLogin } = useAuth();

  return (
    <Container
      alignItems="center"
      display="flex"
      height="100vh"
      justifyContent="center"
    >
      <FormContainer>
        <Title>
          <span>Seedtrace</span> admin app
        </Title>
        <LoginForm onSubmit={onLogin} />
      </FormContainer>
    </Container>
  );
};

export default Login;
