import { gql } from '@apollo/client';
import {
  COMPANY_ANALYTICS_SUMMARY_FIELDS_FRAGMENT,
  DEVICE_INFO_FRAGMENT,
  GEO_ANALYTICS_FRAGMENT,
  PAGE_ACTIVITY_FRAGMENT,
  PRODUCT_ANALYTICS_SUMMARY_FIELDS_FRAGMENT
} from 'graphql/fragments/analytics';

export const GET_COMPANY_ANALYTICS = gql`
  query GetCompanyAnalytics($params: GetAdminCompanyAnalyticsParams!) {
    companyAnalyticsSummary(params: $params) {
      ...productAnalyticsFields
    }
    companyProductsActivity(params: $params) {
      ...pageActivityFields
    }
  }
  ${COMPANY_ANALYTICS_SUMMARY_FIELDS_FRAGMENT}
  ${PAGE_ACTIVITY_FRAGMENT}
`;

export const GET_PRODUCT_ANALYTICS = gql`
  query GetProductAnalytics($params: GetAnalyticsParams!) {
    productAnalyticsSummary(params: $params) {
      ...analyticsSummaryFields
    }
    pageActivity(params: $params) {
      ...pageActivityFields
    }
    deviceInfo(params: $params) {
      ...deviceInfoFields
    }
    geoAnalytics(params: $params) {
      ...geoAnalyticsFields
    }
  }
  ${PRODUCT_ANALYTICS_SUMMARY_FIELDS_FRAGMENT}
  ${PAGE_ACTIVITY_FRAGMENT}
  ${DEVICE_INFO_FRAGMENT}
  ${GEO_ANALYTICS_FRAGMENT}
`;

export const GET_ALL_ANALYTICS = gql`
  query GetAdminAllAnalytics($params: GetAdminAllAnalyticsParams) {
    allAnalyticsSummary(params: $params) {
      ...productAnalyticsFields
    }
    allProductsActivity(params: $params) {
      ...pageActivityFields
    }
  }
  ${COMPANY_ANALYTICS_SUMMARY_FIELDS_FRAGMENT}
  ${PAGE_ACTIVITY_FRAGMENT}
`;
