import { gql } from '@apollo/client';
import {
  IMPACT_CATALOG_ITEM_FRAGMENT,
  IMPACT_CLAIM_FRAGMENT
} from 'graphql/fragments/impactClaims';

export const CHANGE_CLAIM_SUBMISSION_STATUS = gql`
  mutation changeClaimSubmissionStatus(
    $id: UUID!
    $input: ImpactClaimUpdateStatusInput!
  ) {
    updateImpactClaimStatus(id: $id, input: $input) {
      impactClaim {
        ...impactClaimValues
      }
    }
  }
  ${IMPACT_CLAIM_FRAGMENT}
`;

export const UPDATE_IMPACT_CATALOG_ITEM = gql`
  mutation updateImpactCatalogItemInput(
    $id: UUID!
    $input: UpdateAdminImpactCatalogItemInput!
  ) {
    updateImpactCatalogItem(id: $id, input: $input) {
      item {
        ...impactCatalogItemValues
      }
    }
  }
  ${IMPACT_CATALOG_ITEM_FRAGMENT}
`;

export const CREATE_IMPACT_CATALOG_ITEM = gql`
  mutation createImpactCatalogItem($input: CreateAdminImpactCatalogItemInput!) {
    createImpactCatalogItem(input: $input) {
      item {
        ...impactCatalogItemValues
      }
    }
  }
  ${IMPACT_CATALOG_ITEM_FRAGMENT}
`;

export const DELETE_IMPACT_CATALOG_ITEM = gql`
  mutation deleteImpactCatalogItems($ids: [UUID]!) {
    deleteImpactCatalogItems(ids: $ids) {
      ids
    }
  }
`;
