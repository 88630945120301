import React, { Fragment, useMemo, useState } from 'react';
import { Alert, Box, Tab, TableCell, Tabs } from '@mui/material';
import get from 'lodash/get';
import { ImpactClaimSubmission } from 'types/types.types';
import { formatDateTime } from 'utils/dates';
import useSubmissions from 'components/ImpactClaims/hooks/useSubmissions';
import Loader from 'components/Loader/Loader';
import Table from 'components/Table/Table';
import { HeadCell } from 'components/Table/Table';

interface StatusTab {
  count: number;
  status: string;
}

const headCells: HeadCell<ImpactClaimSubmission>[] = [
  {
    id: 'createdTimestamp',
    label: 'Submitted date'
  },
  {
    id: 'impactCatalogItem.title',
    label: 'Claim'
  },
  {
    id: 'company.name',
    label: 'Company'
  },
  {
    id: 'attachmentCount',
    label: 'Attachments'
  },
  {
    id: 'status',
    label: 'Status'
  }
];

const SubmissionsPage: React.FC = () => {
  const { claimSubmissions, error, handleSubmissionClick, loading } =
    useSubmissions();

  const [activeTab, setActiveTab] = useState<string>('TO BE REVIEWED');

  const getTabStatus = (status: ImpactClaimSubmission['status']) =>
    ['ARCHIVED', 'DENIED', 'APPROVED'].includes(status)
      ? status
      : 'TO BE REVIEWED';

  const tabs = useMemo(
    () =>
      claimSubmissions.reduce<StatusTab[]>(
        (statusList, claim) => {
          const statusInTab = getTabStatus(claim.status);
          const matchingStatus = statusList.find(
            ({ status }) => status === statusInTab
          );
          if (matchingStatus) matchingStatus.count++;
          return statusList;
        },
        [
          { status: 'TO BE REVIEWED', count: 0 },
          { status: 'APPROVED', count: 0 },
          { status: 'DENIED', count: 0 },
          { status: 'ARCHIVED', count: 0 }
        ]
      ),
    [claimSubmissions]
  );

  return (
    <Box>
      <Box mb={2}>
        <Tabs
          value={tabs.findIndex(({ status }) => status === activeTab)}
          onChange={(e, tab) => setActiveTab(tabs[tab].status)}
        >
          {tabs.map(({ count, status }) => (
            <Tab
              key={status}
              label={
                <>
                  {status} ({count})
                </>
              }
            />
          ))}
        </Tabs>
      </Box>

      {error && (
        <Box mb={2}>
          <Alert severity="error">{error.message}</Alert>
        </Box>
      )}

      {loading ? (
        <Loader />
      ) : (
        <Table<ImpactClaimSubmission>
          defaultOrderType="desc"
          defaultSorting="createdTimestamp"
          headCells={headCells}
          rows={claimSubmissions.filter(
            claim => getTabStatus(claim.status) === activeTab
          )}
          onRowClick={handleSubmissionClick}
        >
          {row => (
            <Fragment>
              {headCells.map(({ id }) => {
                // Date cell
                if (id === 'createdTimestamp') {
                  return (
                    <TableCell key={id}>
                      {formatDateTime(get(row, id))}
                    </TableCell>
                  );
                }

                // Other cells
                return <TableCell key={id}>{get(row, id)}</TableCell>;
              })}
            </Fragment>
          )}
        </Table>
      )}
    </Box>
  );
};

export default SubmissionsPage;
