import styled from 'styled-components';

export const Label = styled.div`
  color: ${({ theme }) => theme.palette.grey['500']};
  font-size: 13px;
`;

export const Value = styled.div`
  font-size: 14px;
`;
