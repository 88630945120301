import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StatisticWrapper = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(2)};
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border-radius: 10px;
  position: relative;
  background: #fff;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const StatisticLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey['500']};
  font-size: 13px;
  text-transform: capitalize;
`;
