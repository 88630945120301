import lowerCase from 'lodash/lowerCase';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import { CompanyFeature } from 'types/companies.types';

export const toLabelCase = (value: string): string => {
  return startCase(lowerCase(value));
};

/**
 * Loops through the features, checks if they are turned on and then return the correct feature array value.
 * Needs to be used as the features the backend returns are not the same on the company and list feature calls.
 * @param features
 * @returns
 */
export const createCompanyFeaturesList = (
  features: CompanyFeature
): string[] => {
  return Object.entries(features)
    .map(([key, value]) => {
      if (key.startsWith('__')) {
        return null;
      }

      if (value === false) {
        return null;
      }

      // Convert it to the same casing the feature array uses from the backend.
      return snakeCase(key).toUpperCase();
    })
    .filter(casedFeature => casedFeature !== null) as string[];
};
