export const IMGIX_URL_PATH = {
  PRD: 'https://seedtrace-prd.imgix.net/',
  STG: 'https://seedtrace-stg.imgix.net/',
  DEV: 'https://seedtrace-dev.imgix.net/'
};

/**
 * Get the imgix URL based on the firebase bucket URL, to match the environment
 * @param bucket
 * @returns
 */
export const getImgixUrlFromFirebaseBucket = (bucket: string): string => {
  if (bucket.includes('-dev-')) {
    return IMGIX_URL_PATH['DEV'];
  }
  if (bucket.includes('-stg-')) {
    return IMGIX_URL_PATH['STG'];
  }

  return IMGIX_URL_PATH['PRD'];
};
