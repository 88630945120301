import sortBy from 'lodash/sortBy';
import { GraphQlConnection, ImpactClaimLog } from 'types/types.types';

const queryPolicies = {
  Query: {
    fields: {
      impactClaimHistory: (existingData: GraphQlConnection<ImpactClaimLog>) => {
        return { edges: sortBy(existingData?.edges, 'node.timestamp', 'desc') };
      },
    },
  },
};

export default queryPolicies;
