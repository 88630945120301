import styled from 'styled-components';

export const Container = styled.a<{
  active: boolean;
}>`
  display: flex;
  justify-content: flex;
  align-items: center;
  grid-gap: 10px;
  background: white;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border-left: 8px solid transparent;
  width: 100%;
  max-width: 500px;
  border-radius: 6px;
  padding: 8px 16px;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
  ${({ active, theme }) =>
    active &&
    `
    border-left-color: ${theme.palette.grey.A700};
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

export const Title = styled.span<{
  bold: boolean;
}>`
  color: #000;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  font-size: 14px;
`;

export const SubTitle = styled.span`
  color: #7b7b7b;
  font-weight: 400;
  font-size: 11px;
`;

export const IconContainer = styled.div`
  min-width: 24px;
`;
