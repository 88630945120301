import React, { useMemo, useRef } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { EMPTY_RAW_MATERIAL } from 'constants/RawMaterials';
import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang,
} from 'types/rawMaterials.types';
import {
  ButtonContainer,
  FieldsContainer,
  StyledForm,
} from 'components/ImpactClaims/ActiveImpactClaim/ImpactClaimForm/styles';
import MultiLangFields from '../MultiLangFields/MultiLangFields';
import RawMaterialStatus from '../RawMaterialStatusBlock/RawMaterialStatusBlock';

interface IRawMaterialForm {
  creatingRawMaterial: boolean;
  handleUpdateRawMaterialStatus: (
    status: IRawMaterialLogAction
  ) => Promise<void>;
  initialRawMaterial?: IRawMaterialMultiLang;
  onSubmit: SubmitHandler<IRawMaterialMultiLang>;
}

const RawMaterialForm: React.FC<IRawMaterialForm> = ({
  creatingRawMaterial,
  handleUpdateRawMaterialStatus,
  initialRawMaterial,
  onSubmit,
}: IRawMaterialForm) => {
  const defaultValues = useMemo(
    () =>
      !creatingRawMaterial && initialRawMaterial
        ? initialRawMaterial
        : EMPTY_RAW_MATERIAL,
    [creatingRawMaterial, initialRawMaterial]
  );
  const methods = useForm<IRawMaterialMultiLang>({
    defaultValues,
  });

  const formRef = useRef<HTMLFormElement>(null);

  const submitAndApprove = () => {
    if (formRef.current) {
      methods.setValue('latestLogAction', 'APPROVED' as IRawMaterialLogAction);
      // Dispatch a submit event so the form receives all new values from the useForm hook.
      // Recommended way of remote submission of the form according to the docs: https://codesandbox.io/s/remote-handlesubmit-ipbqb?file=/src/App.js
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
        {!creatingRawMaterial && initialRawMaterial && (
          <RawMaterialStatus
            activeRawMaterial={initialRawMaterial}
            handleUpdateRawMaterialStatus={handleUpdateRawMaterialStatus}
            isFormDirty={methods.formState.isDirty}
            triggerValidation={methods.trigger}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FieldsContainer>
              <MultiLangFields initialRawMaterial={defaultValues} />
            </FieldsContainer>
          </Grid>
        </Grid>

        <ButtonContainer>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => methods.reset()}
          >
            Discard
          </Button>
          {!creatingRawMaterial &&
            initialRawMaterial?.latestLogAction !== 'APPROVED' && (
              <Button
                color="secondary"
                size="large"
                variant="outlined"
                onClick={submitAndApprove}
              >
                Save & Approve
              </Button>
            )}
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </ButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};

export default RawMaterialForm;
