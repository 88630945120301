import styled from 'styled-components';

export const CategoryContainer = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  grid-gap: 10px;

  svg {
    height: 14px;
  }
`;
