import { Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Box)`
  background: ${({ theme }) => theme.palette.primary.main};
  box-shadow: ${({ theme }) => theme.shadows[0]};
`;

export const Title = styled.h1`
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.primary.main};

  span {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const FormContainer = styled(Box)`
  background: #fff;
  border-radius: 5px;
  color: #fff;
  padding: ${({ theme }) => theme.spacing(6)};
`;
