import { ThemeOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import merge from 'lodash/merge';
import 'styles/app.css';

export interface CompanyTheme {
  statusColor: {
    APPROVED: string;
    ARCHIVED: string;
    CREATED: string;
    DELETED: string;
    DENIED: string;
    REQUESTED: string;
    UPDATED: string;
  };
}

const defaults: ThemeOptions = {
  palette: {
    secondary: {
      main: '#FFB801',
      light: '#FFFAEB'
    },
    primary: {
      light: '#253561',
      main: '#0C193B'
    },
    background: {
      default: '#f4f5fa'
    }
  },
  typography: {
    fontFamily: 'Lato',
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: 600
    },
    h3: {
      textTransform: 'uppercase',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '19px',
      letterSpacing: '0.1em',
      textAlign: 'left',
      margin: '5px 10px 0 0',
      marginBottom: '8px'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    }
  }
};

const companyTheme: CompanyTheme = {
  statusColor: {
    APPROVED: '#2DB875',
    CREATED: '#000',
    UPDATED: '#B2BCEB',
    REQUESTED: '#B2BCEB',
    DENIED: '#E74E4E',
    ARCHIVED: '#AFAFAF',
    DELETED: '#AFAFAF'
  }
};

const theme = createTheme(defaults);

export default merge(theme, companyTheme);
