import React from 'react';
import { Button } from '@mui/material';
import { getImgixUrlFromFirebaseBucket } from 'constants/Imgix';
import {
  getMetadata,
  getStorage,
  ref,
  uploadBytesResumable
} from 'firebase/storage';
import { ImageAdd } from 'styles/icons';
import { v4 as uuid } from 'uuid';
import { ImageInput } from './styles';

const uploadFile = (
  file: File,
  targetFolder: string,
  onFileUploadComplete?: (downloadURL: string) => void,
  onFileUploadError?: (error: Error) => void
) => {
  const storage = getStorage();
  // adds a uuid to the filename to make sure diplicate filenames don't override the current image and to prevent two items pointing to the same image.
  const uniqueFileName = `${targetFolder}${uuid()}-${file.name}`;
  const storageRef = ref(storage, uniqueFileName);
  const uploadTask = uploadBytesResumable(storageRef, file);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    'state_changed',
    undefined,
    error => {
      if (onFileUploadError) {
        onFileUploadError(error);
      }
    },
    () => {
      // Upload completed successfully, now we can get the URL
      getMetadata(uploadTask.snapshot.ref).then(({ bucket, fullPath }) => {
        if (onFileUploadComplete) {
          // Custom create the download URL, because we need the imgix url in the consumer app, without encoding!
          onFileUploadComplete(
            getImgixUrlFromFirebaseBucket(bucket) + fullPath
          );
        }
      });
    }
  );
};

interface Props {
  buttonText: string;
  onFileUploadComplete?: (downloadURL: string) => void;
  onFileUploadError?: (error: Error) => void;
  targetFolder?: string;
}

const FileUpload: React.FC<Props> = ({
  buttonText,
  onFileUploadComplete,
  onFileUploadError,
  targetFolder = ''
}) => {
  return (
    <label htmlFor="upload-file-input">
      <ImageInput
        accept="image/png, image/jpeg"
        id="upload-file-input"
        type="file"
        onChange={event => {
          if (event.target.files && event.target.files[0]) {
            uploadFile(
              event.target.files[0],
              targetFolder,
              onFileUploadComplete,
              onFileUploadError
            );
          }
        }}
      />
      <Button
        component="span"
        startIcon={<ImageAdd size={20} />}
        variant="contained"
      >
        {buttonText}
      </Button>
    </label>
  );
};

export default FileUpload;
