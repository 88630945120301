import gql from 'graphql-tag';

export const GET_SDGS = gql`
  query GetSustainableDevGoals {
    sdgs {
      edges {
        node {
          id
          number
          title
          description
          color
          iconUrl
        }
      }
    }
  }
`;
