import React from 'react';
import { Grid } from '@mui/material';
import { AnalyticsSummary } from 'types/analytics.types';
import MetricBlock from './MetricBlock';
import { MetricsContainer } from './styles';

interface Props {
  analyticsSummary: AnalyticsSummary;
}

const AnalyticsKeyMetrics: React.FC<Props> = ({ analyticsSummary }) => {
  return (
    <MetricsContainer>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.views}
            title={'Views Total'}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.averageTimeSpent}
            title={'Average time spent'}
            variant="TIME"
          />
        </Grid>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.directViews}
            title={'Direct views'}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.uniqueDirectViews}
            title={'Unique Direct Views'}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.uniqueViews}
            title={'Unique Views'}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.uniqueQrViews}
            title={'Unique QR Views'}
          />
        </Grid>
        <Grid item xs={4}>
          <MetricBlock
            summaryItem={analyticsSummary.qrViews}
            title={'QR views'}
          />
        </Grid>
      </Grid>
    </MetricsContainer>
  );
};

export default AnalyticsKeyMetrics;
