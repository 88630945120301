import styled from 'styled-components';

export const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Pill = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  overflow: hidden;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

export const Text = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 8px;
  font-weight: 600;
  background: #fff;
`;

export const IconBox = styled.div<{
  background: string;
}>`
  height: 100%;
  background: ${({ background }) => background};
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;

  img {
    height: 16px;
    width: auto;
  }
`;
