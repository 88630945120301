import { Grid } from '@mui/material';
import styled from 'styled-components';

export const DottedLine = styled.div`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => theme.palette.grey[300]};
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const ListItemActions = styled(Grid)`
  display: flex;
  grid-gap: 10px;
`;

export const DragIcon = styled.div``;

export const DeleteRow = styled.div`
  margin-top: 1px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;
