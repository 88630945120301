import { gql } from '@apollo/client';
import { CERTIFICATION_CATALOG_ITEM_FRAGMENT } from 'graphql/fragments';

export const CREATE_CERTIFICATION_CATALOG_ITEM = gql`
  mutation CreateCertificateCatalogItem(
    $input: CreateCertificateCatalogItemInput!
  ) {
    createCertificateCatalogItem(input: $input) {
      item {
        ...certificateCatalogItemValues
      }
    }
  }
  ${CERTIFICATION_CATALOG_ITEM_FRAGMENT}
`;

export const UPDATE_CERTIFICATION_CATALOG_ITEM = gql`
  mutation UpdateCertificateCatalogItem(
    $id: UUID!
    $input: UpdateCertificateCatalogItemInput!
  ) {
    updateCertificateCatalogItem(id: $id, input: $input) {
      item {
        ...certificateCatalogItemValues
      }
    }
  }
  ${CERTIFICATION_CATALOG_ITEM_FRAGMENT}
`;

export const DELETE_CERTIFICATION_CATALOG_ITEMS = gql`
  mutation DeleteCertificateCatalogItem($ids: [UUID]!) {
    deleteCertificateCatalogItems(ids: $ids) {
      ids
    }
  }
`;
