import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { Trash } from 'styles/icons';
import { getEnglishValue } from 'utils/translations';
import ImpactClaimForm from 'components/ImpactClaims/ActiveImpactClaim/ImpactClaimForm/ImpactClaimForm';
import { GoBackLink } from 'components/ImpactClaims/ActiveSubmission/styles';
import useImpactClaims from 'components/ImpactClaims/hooks/useImpactClaims';
import ConfirmDialog from 'components/shared/ConfirmDialog/ConfirmDialog';

const ActiveImpactClaim: React.FC = () => {
  const { impactClaimId } = useParams();
  const creatingNewClaim = !impactClaimId || impactClaimId === 'new';
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const toggleConfirmDelete = () => setOpenConfirmDelete(curr => !curr);

  const {
    activeImpactClaim,
    handleDeleteImpactClaim,
    handleSaveCreate,
    handleSaveUpdate
  } = useImpactClaims({
    activeClaimId: impactClaimId
  });

  if (!activeImpactClaim && !creatingNewClaim) {
    return null;
  }

  return (
    <Box p={2}>
      <Box mb={3}>
        <GoBackLink to="/impact-claims">Go back</GoBackLink>
      </Box>

      <Box alignItems="center" display="flex" justifyContent="space-between">
        <h2>
          {creatingNewClaim
            ? 'Create new impact claim'
            : getEnglishValue(activeImpactClaim?.title)}
        </h2>
        {!creatingNewClaim && (
          <IconButton color="error" onClick={toggleConfirmDelete}>
            <Trash size={14} />
          </IconButton>
        )}
      </Box>

      <ConfirmDialog
        confirmText="Yes, delete"
        open={openConfirmDelete}
        onClose={toggleConfirmDelete}
        onConfirm={() => {
          if (!impactClaimId) return;
          handleDeleteImpactClaim(impactClaimId);
          toggleConfirmDelete();
        }}
      >
        You are about to delete this impact claim, this cannot be undone.
      </ConfirmDialog>

      <ImpactClaimForm
        creatingNewClaim={creatingNewClaim}
        initialImpactClaim={activeImpactClaim}
        onSubmit={creatingNewClaim ? handleSaveCreate : handleSaveUpdate}
      />
    </Box>
  );
};

export default ActiveImpactClaim;
