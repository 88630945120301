import React, { Fragment } from 'react';
import { Box, Divider, styled, Typography } from '@mui/material';
import { StatisticLabel, StatisticWrapper } from './styles';

interface Props {
  icon: JSX.Element;
  primaryIndicator: string | number;
  secondaryIndicators?: Array<{ label: string; value: string | number }>;
  title: string;
}

const StatisticIcon = styled(Box)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const StatisticBlock: React.FC<Props> = ({
  icon,
  primaryIndicator,
  secondaryIndicators,
  title
}) => {
  return (
    <StatisticWrapper>
      <Box>
        <StatisticLabel variant="caption">{title}</StatisticLabel>
        <Typography variant="h5">{primaryIndicator}</Typography>
      </Box>

      <StatisticIcon>{icon}</StatisticIcon>

      {secondaryIndicators && (
        <Fragment>
          <Divider sx={{ my: 2 }} />
          <Box>
            {secondaryIndicators.map(indicator => (
              <Box
                key={indicator.label}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <StatisticLabel variant="caption">
                  {indicator.label}
                </StatisticLabel>
                <Typography variant="body2">{indicator.value}</Typography>
              </Box>
            ))}
          </Box>
        </Fragment>
      )}
    </StatisticWrapper>
  );
};

export default StatisticBlock;
