import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { Trash } from 'styles/icons';
import { IRawMaterialLogAction } from 'types/rawMaterials.types';
import { getEnglishValue } from 'utils/translations';
import { GoBackLink } from 'components/ImpactClaims/ActiveSubmission/styles';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import useRawMaterials from '../hooks/useRawMaterials';
import RawMaterialForm from './RawMaterialForm/RawMaterialForm';

const ActiveRawMaterial: React.FC = () => {
  const { rawMaterialId } = useParams();
  const creatingRawMaterial = !rawMaterialId || rawMaterialId === 'new';
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const toggleConfirmDelete = () => setOpenConfirmDelete(curr => !curr);

  const {
    activeRawMaterial,
    handleCreateRawMaterial,
    handleUpdateRawMaterial,
    handleUpdateRawMaterialStatus
  } = useRawMaterials({
    activeRawMaterialId: rawMaterialId
  });

  const handleDeleteRawMaterial = async () => {
    if (!rawMaterialId) return;

    await handleUpdateRawMaterialStatus(
      'DELETED' as IRawMaterialLogAction.DELETED
    );
    toggleConfirmDelete();
  };

  return (
    <Box p={2}>
      <Box mb={3}>
        <GoBackLink
          to={
            activeRawMaterial?.latestLogAction === 'APPROVED'
              ? '/raw-materials'
              : '/raw-materials/requests'
          }
        >
          Go back
        </GoBackLink>
      </Box>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <h2>
          {creatingRawMaterial
            ? 'Create new raw material'
            : getEnglishValue(activeRawMaterial?.title)}
        </h2>
        {!creatingRawMaterial && (
          <IconButton color="error" onClick={toggleConfirmDelete}>
            <Trash size={14} />
          </IconButton>
        )}
      </Box>
      <RawMaterialForm
        creatingRawMaterial={creatingRawMaterial}
        handleUpdateRawMaterialStatus={handleUpdateRawMaterialStatus}
        initialRawMaterial={activeRawMaterial}
        onSubmit={values =>
          creatingRawMaterial
            ? handleCreateRawMaterial(values)
            : handleUpdateRawMaterial(values)
        }
      />
      <ConfirmDialog
        confirmText="Yes, delete"
        open={openConfirmDelete}
        onClose={toggleConfirmDelete}
        onConfirm={handleDeleteRawMaterial}
      >
        You are about to delete this certification, this cannot be undone.
      </ConfirmDialog>
    </Box>
  );
};

export default ActiveRawMaterial;
