import { gql } from '@apollo/client';

export const CERTIFICATION_CATALOG_ITEM_FRAGMENT = gql`
  fragment certificateCatalogItemValues on AdminCertificateCatalogItem {
    id
    title {
      language
      value
    }
    description {
      language
      value
    }
    imageUrl
    status
    requestedByUserEmail
    requestedTimestamp
  }
`;
