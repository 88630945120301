import React, { Fragment } from 'react';
import { Avatar, Box, Button, TableCell } from '@mui/material';
import get from 'lodash/get';
import { ImpactCatalogItemMultiLang } from 'types/types.types';
import { getEnglishValue } from 'utils/translations';
import ClaimCategory from 'components/ImpactClaims/ClaimCategory/ClaimCategory';
import useImpactClaims from 'components/ImpactClaims/hooks/useImpactClaims';
import Table, { HeadCell } from 'components/Table/Table';

const ICON_SIZE = 35;

const headCells: HeadCell<ImpactCatalogItemMultiLang<string | undefined>>[] = [
  {
    id: 'iconUrl',
    label: '',
  },
  {
    id: 'title',
    label: 'Claim',
  },
  {
    id: 'categoryType',
    label: 'Category',
  },
];

const ImpactClaimsPage: React.FC = () => {
  const { handleImpactClaimClick, handleOpenNewForm, impactClaims } =
    useImpactClaims();

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button variant="contained" onClick={handleOpenNewForm}>
          Add new
        </Button>
      </Box>

      <Table<ImpactCatalogItemMultiLang<string | undefined>>
        defaultSorting="title"
        headCells={headCells}
        rows={impactClaims.map(value => {
          return {
            ...value,
            title: getEnglishValue(value.title),
          };
        })}
        onRowClick={handleImpactClaimClick}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              if (id === 'iconUrl') {
                return (
                  <TableCell key={id} size="small" sx={{ width: '60px' }}>
                    <Avatar
                      src={row.iconUrl}
                      sx={{ width: ICON_SIZE, height: ICON_SIZE }}
                    />
                  </TableCell>
                );
              }

              if (id === 'category') {
                return (
                  <TableCell key={id}>
                    <ClaimCategory categoryType={row.categoryType} />
                  </TableCell>
                );
              }

              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </Box>
  );
};

export default ImpactClaimsPage;
