import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledForm = styled.form`
  margin-bottom: ${({ theme }) => theme.spacing(10)};
`;

export const ButtonContainer = styled(Box)`
  background: ${({ theme }) => theme.palette.primary.main};
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%);
  padding: ${({ theme }) => theme.spacing(2)};
  z-index: 1;
  grid-gap: ${({ theme }) => theme.spacing(2)};
  display: flex;
  border-radius: 4px;
`;

export const FieldsContainer = styled(Box)`
  background: #fff;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(2)};
`;
