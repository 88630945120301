import React from 'react';
import { SustainableDevGoals } from 'types/types.types';
import { IconBox, Pill, PillContainer, Text } from './styles';

interface Props {
  className?: string;
  sdgs: SustainableDevGoals[];
}

const SdgPills: React.FC<Props> = ({ className, sdgs }: Props): JSX.Element => {
  return (
    <PillContainer className={className}>
      {sdgs.map(sdg => {
        const { color, iconUrl, number, title } = sdg;

        return (
          <Pill key={`sdg-pill-${number}`}>
            <IconBox background={color}>
              <img alt={`sdg ${number} logo`} src={iconUrl} />
            </IconBox>
            <Text>{title}</Text>
          </Pill>
        );
      })}
    </PillContainer>
  );
};

export default SdgPills;
