import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, Grid, TextField, TextFieldProps } from '@mui/material';
import languages from 'constants/languages';
import startCase from 'lodash/startCase';
import { MultiLanguageString } from 'types/types.types';
import { getLanguageIndex, getLanguageValue } from 'utils/translations';

interface Props {
  error: boolean;
  field: string;
  languageArray: MultiLanguageString;
}

const MultiLangTextField: React.FC<Props & TextFieldProps> = ({
  error,
  field,
  languageArray,
  ...rest
}: Props & TextFieldProps) => {
  const { register } = useFormContext();

  return (
    <Grid container spacing={2}>
      {languages.map(({ short: lang }) => {
        return (
          <Grid
            item
            key={lang + field}
            md={(12 - 1) / languages.length}
            xs={12}
          >
            <FormControl fullWidth error={error}>
              <TextField
                {...register(
                  `${field}[${getLanguageIndex(languageArray, lang)}].value`
                )}
                fullWidth
                defaultValue={getLanguageValue(languageArray, lang)}
                label={`${startCase(field)} (${lang})`}
                variant="outlined"
                {...rest}
              />
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MultiLangTextField;
