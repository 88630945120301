import { gql } from '@apollo/client';

export const PRODUCT_FRAGMENT = gql`
  fragment companyProductValues on Product {
    id
    title
    slug
    publicUrl
    imageUrl
    status
    publishedLanguages
    languageConfig {
      default
      enabled
      default
    }
  }
`;
