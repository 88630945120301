export interface ConfigStatics {
  productURL: string;
}

class Config {
  fireBaseConfig;

  constructor(config?: ConfigStatics) {
    Object.assign(this, config);
    this.fireBaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
  }
}

export default Config;
