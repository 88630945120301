import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Grid, TextField } from '@mui/material';
import { LoginForm as LoginFormProps } from 'types/types.types';

interface Props {
  onSubmit: SubmitHandler<LoginFormProps>;
}

const LoginForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<LoginFormProps>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            {...register('email', { required: true })}
            fullWidth
            color="secondary"
            error={!!errors.email}
            label="Email"
            size="small"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...register('password', { required: true })}
            fullWidth
            autoComplete="on"
            color="secondary"
            error={!!errors.password}
            label="Password"
            size="small"
            type="password"
            variant="standard"
          />
        </Grid>
        <Grid item mt={2} xs={12}>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Login
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginForm;
