import React, { Fragment, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import xor from 'lodash/xor';
import { CompanyFeature } from 'types/companies.types';
import { createCompanyFeaturesList, toLabelCase } from 'utils/companyUtils';
import { useFeatureList } from 'components/Companies/hooks/useFeatureList';
import { useUpdateCompany } from 'components/Companies/hooks/useUpdateCompany';
import BooleanValueIndicator from 'components/shared/BooleanValueIndicator/BooleanValueIndicator';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { Label } from 'components/shared/LabelWithValue/styles';
import { StyledCheckbox, StyledContainer, StyledGrid } from '../styles';

interface Props {
  companyId: string;
  features: CompanyFeature;
}

const EDITABLE_FEATURES: string[] = [
  'WHITE_LABELING',
  'VERIFIED_PAYMENTS',
  'COMPLIANCE',
  'PAYMENTS'
];

const CompanyFeatures: React.FC<Props> = ({ companyId, features }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { companyFeatures, loading } = useFeatureList();
  const { updateCompanyFeatures } = useUpdateCompany({
    onSuccess: () => {
      setIsEditing(false);
    }
  });

  const [activeFeatures, setActiveFeatures] = useState<string[]>(
    createCompanyFeaturesList(features) || []
  );

  if (loading) {
    return (
      <StyledContainer
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress color="secondary" />
      </StyledContainer>
    );
  }

  if (!companyFeatures) {
    return (
      <StyledContainer>
        <Alert severity="warning">Could not find any company features</Alert>
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between">
        <Typography gutterBottom fontWeight={700} variant="subtitle1">
          Features
        </Typography>
        <div>
          {isEditing && (
            <Button size="small" onClick={() => setIsEditing(!isEditing)}>
              Cancel
            </Button>
          )}
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              if (isEditing) {
                updateCompanyFeatures({
                  variables: {
                    id: companyId,
                    input: {
                      features: activeFeatures
                    }
                  }
                });
              } else {
                setIsEditing(true);
              }
            }}
          >
            {isEditing ? 'Save' : 'Edit features'}
          </Button>
        </div>
      </Box>

      <StyledGrid container={true} spacing={3}>
        {isEditing ? (
          <Fragment>
            {EDITABLE_FEATURES.map(feature => {
              return (
                <Grid item key={`checkbox-${feature}`}>
                  <Label>{toLabelCase(feature)}</Label>
                  <StyledCheckbox
                    checked={activeFeatures.includes(feature)}
                    onChange={() => {
                      // toggles the selected feature on/off in the activeFeatures array.
                      setActiveFeatures(xor(activeFeatures, [feature]));
                    }}
                  />
                </Grid>
              );
            })}
          </Fragment>
        ) : (
          <Fragment>
            {companyFeatures.map(feature => {
              return (
                <LabelWithValue
                  key={`company-feature-${feature}`}
                  label={toLabelCase(feature)}
                >
                  <BooleanValueIndicator
                    value={get(features, camelCase(feature))}
                  />
                </LabelWithValue>
              );
            })}
          </Fragment>
        )}
      </StyledGrid>
    </StyledContainer>
  );
};

export default CompanyFeatures;
