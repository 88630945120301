import { MultiLanguageString } from 'types/types.types';

export function getLanguageValue(
  multiLanguageValue: MultiLanguageString | undefined,
  language: string
): string | undefined {
  return (
    multiLanguageValue?.find(val => val.language === language)?.value || ''
  );
}

export function getEnglishValue(
  multiLanguageValue: MultiLanguageString | undefined
): string | undefined {
  return getLanguageValue(multiLanguageValue, 'en');
}

export function getLanguageIndex(
  multiLanguageValue: MultiLanguageString,
  language: string
): number {
  return multiLanguageValue.findIndex(val => val.language === language);
}
