import React from 'react';
import { ReactComponent as iconEco } from 'images/claims/claim-icon-eco.svg';
import { ReactComponent as iconSocial } from 'images/claims/claim-icon-social.svg';
import { ReactComponent as symbolEco } from 'images/claims/claim-symbol-eco.svg';
import { ReactComponent as symbolSocial } from 'images/claims/claim-symbol-social.svg';
import { ReactComponent as eco } from 'images/claims/eco.svg';
import { ReactComponent as social } from 'images/claims/social.svg';
import { ImpactCatalogCategoryType } from 'types/types.types';
import { CategoryContainer } from './styles';

interface Props {
  categoryType: ImpactCatalogCategoryType;
}

const categoryConfig = {
  ECOLOGICAL: {
    title: 'Ecological Claims',
    titleSingular: 'Ecological Claim',
    iconWithBackground: symbolEco,
    icon: eco,
    successIcon: iconEco,
    catalogQueryAlias: 'ecologicalItems',
    color: '#2DB875',
  },
  SOCIAL: {
    title: 'Social Claims',
    titleSingular: 'Social Claim',
    iconWithBackground: symbolSocial,
    icon: social,
    successIcon: iconSocial,
    catalogQueryAlias: 'socialItemscal',
    color: '#FFB801',
  },
};

const ClaimCategory: React.FC<Props> = ({ categoryType }: Props) => {
  if (!categoryType) return null;

  const CatTitle = categoryConfig[categoryType].titleSingular;
  const CatIcon = categoryConfig[categoryType].icon;
  const catColor = categoryConfig[categoryType].color;

  return (
    <CategoryContainer color={catColor}>
      <CatIcon />
      {CatTitle}
    </CategoryContainer>
  );
};

export default ClaimCategory;
