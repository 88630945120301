import React from 'react';
import { Grid } from '@mui/material';
import { Label, Value } from './styles';

interface Props {
  children: React.ReactChild;
  label: string;
}

const LabelWithValue: React.FC<Props> = ({ children, label }: Props) => {
  return (
    <Grid item>
      <Label>{label}</Label>
      <Value>{children}</Value>
    </Grid>
  );
};

export default LabelWithValue;
