import React from 'react';
import { CheckCircle, XLg } from 'styles/icons';

interface BooleanIconProps {
  value: boolean | undefined;
}

const BooleanValueIndicator: React.FC<BooleanIconProps> = ({ value }) => {
  if (value) {
    return <CheckCircle size={15} />;
  }

  return <XLg size={12} />;
};

export default BooleanValueIndicator;
