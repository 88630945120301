import { gql } from '@apollo/client';

export const ADDRESS_FRAGMENT = gql`
  fragment address on Address {
    street
    streetNumber
    zipCode
    city
    country
  }
`;
