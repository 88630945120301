import { Typography } from '@mui/material';
import styled from 'styled-components';

export const MetricsContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(3)};
  flex-wrap: wrap;
`;

export const MetricBlockWrapper = styled('div')`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const MetricValueText = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
`;
