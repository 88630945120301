import React, { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TableCell } from '@mui/material';
import get from 'lodash/get';
import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang,
} from 'types/rawMaterials.types';
import { getEnglishValue } from 'utils/translations';
import Table, { HeadCell } from 'components/Table/Table';
import useRawMaterials from './hooks/useRawMaterials';

const headCells: HeadCell<IRawMaterialMultiLang<string | undefined>>[] = [
  {
    id: 'title',
    label: 'Raw material',
  },
  {
    id: 'latestLogAction',
    label: 'Status',
  },
];

interface IManageRawMaterialsPageProps {
  activeRawMaterialStatuses: IRawMaterialLogAction[];
}

const ManageRawMaterialsPage: FC<IManageRawMaterialsPageProps> = ({
  activeRawMaterialStatuses,
}) => {
  const navigate = useNavigate();
  const { handleRawMaterialClick, rawMaterials } = useRawMaterials({
    activeRawMaterialStatuses,
  });

  return (
    <Box>
      {/* Wait until backend is ready */}
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          onClick={() => navigate(`/raw-materials/new`)}
        >
          Add new
        </Button>
      </Box>
      <Table<IRawMaterialMultiLang<string | undefined>>
        defaultSorting="title"
        headCells={headCells}
        rows={rawMaterials.map(value => {
          return {
            ...value,
            title: getEnglishValue(value.title),
          };
        })}
        onRowClick={handleRawMaterialClick}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </Box>
  );
};

export default ManageRawMaterialsPage;
