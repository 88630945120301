import React from 'react';
import { useLocation } from 'react-router';
import { Box, Toolbar, Typography } from '@mui/material';
import { useMenuItems } from 'components/Menu/hooks';
import Menu from '../Menu/Menu';
import { PageInner, StyledAppBar } from './styles';
interface Props {
  children: React.ReactChild;
}
const drawerWidth = 240;

const PageContainer: React.FC<Props> = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { getMenuItemByUrl } = useMenuItems();

  return (
    <Box sx={{ display: 'flex' }}>
      <StyledAppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`
        }}
      >
        <Toolbar>
          <Typography noWrap component="div" variant="h6">
            {getMenuItemByUrl(pathname)?.text || ''}
          </Typography>
        </Toolbar>
      </StyledAppBar>
      <Menu drawerWidth={drawerWidth} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        <PageInner>{children}</PageInner>
      </Box>
    </Box>
  );
};

export default PageContainer;
