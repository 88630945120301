import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { Trash } from 'styles/icons';
import { getEnglishValue } from 'utils/translations';
import useCertifications from 'components/Certifications/hooks/useCertifications';
import { GoBackLink } from 'components/ImpactClaims/ActiveSubmission/styles';
import ConfirmDialog from '../../shared/ConfirmDialog/ConfirmDialog';
import CertificationForm from './CertificationForm/CertificationForm';

const ActiveCertificationCatalogItem: React.FC = () => {
  const { certificationId } = useParams();
  const creatingCertification = !certificationId || certificationId === 'new';
  const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
  const toggleConfirmDelete = () => setOpenConfirmDelete(curr => !curr);

  const {
    activeCertification,
    handleCreateCatalogItem,
    handleDeleteCatalogItem,
    handleUpdateCatalogItem,
    handleUpdateCatalogItemStatus
  } = useCertifications({
    activeCertificationId: certificationId
  });

  return (
    <Box p={2}>
      <Box mb={3}>
        <GoBackLink
          to={
            activeCertification?.status === 'APPROVED'
              ? '/certifications'
              : '/certifications/requests'
          }
        >
          Go back
        </GoBackLink>
      </Box>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <h2>
          {creatingCertification
            ? 'Create new certification'
            : getEnglishValue(activeCertification?.title)}
        </h2>
        {!creatingCertification && (
          <IconButton color="error" onClick={toggleConfirmDelete}>
            <Trash size={14} />
          </IconButton>
        )}
      </Box>

      <CertificationForm
        creatingCertification={creatingCertification}
        handleUpdateCatalogItemStatus={handleUpdateCatalogItemStatus}
        initialCertification={activeCertification}
        onSubmit={
          creatingCertification
            ? handleCreateCatalogItem
            : handleUpdateCatalogItem
        }
      />
      <ConfirmDialog
        confirmText="Yes, delete"
        open={openConfirmDelete}
        onClose={toggleConfirmDelete}
        onConfirm={() => {
          if (!certificationId) return;
          handleDeleteCatalogItem(certificationId);
          toggleConfirmDelete();
        }}
      >
        You are about to delete this certification, this cannot be undone.
      </ConfirmDialog>
    </Box>
  );
};

export default ActiveCertificationCatalogItem;
