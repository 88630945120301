// https://stackoverflow.com/a/30810322
const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');

  textArea.value = text;
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy');
  document.body.removeChild(textArea);
};

const copyTextToClipboard = (text: string): void | Promise<void> =>
  navigator.clipboard
    ? navigator.clipboard.writeText(text)
    : fallbackCopyTextToClipboard(text);

export default copyTextToClipboard;
