import React from 'react';
import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import ClaimDetails from 'components/ImpactClaims/ActiveSubmission/ClaimDetails/ClaimDetails';
import SubmissionDetails from 'components/ImpactClaims/ActiveSubmission/SubmissionDetails/SubmissionDetails';
import { GoBackLink } from './styles';

const ActiveSubmission: React.FC = () => {
  return (
    <Box p={2}>
      <Box mb={3}>
        <GoBackLink to="/impact-claims/submissions">Go back</GoBackLink>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SubmissionDetails />
        </Grid>
        <Grid item xs={6}>
          <ClaimDetails />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActiveSubmission;
