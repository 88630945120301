import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { LOGIN, LOGOUT } from 'graphql/mutations';
import { LoginForm } from 'types/types.types';

interface locationState {
  redirect: string;
}

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as locationState;

  // const setBackendError = useBackendErrorMessage();
  const [login] = useMutation<any, LoginForm>(LOGIN, {
    onError: error => {
      console.error(error);
      toast(
        'We are experiencing issues logging you in. Please try again or contact our support.'
      );
    },
    onCompleted: () => {
      const redirect = state?.redirect || '/';
      navigate(redirect);
    },
    fetchPolicy: 'no-cache',
  });
  // const setBackendError = useBackendErrorMessage();
  const [logout] = useMutation<null, null>(LOGOUT, {
    onError: error => {
      console.error(error);
      toast('Logout failed');
    },
    onCompleted: () => {
      navigate('/login');
    },
    fetchPolicy: 'no-cache',
  });

  const onLogin = ({ email, password }: LoginForm) => {
    return login({
      variables: { email, password },
    });
  };

  const onLogout = () => {
    return logout();
  };

  return { onLogin, onLogout };
};

export default useAuth;
