import React, {
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
  useState
} from 'react';
import MapGL, {
  MapEvent,
  Marker,
  MarkerDragEvent,
  NavigationControl
} from 'react-map-gl';
import { MapCallbacks } from 'react-map-gl/dist/esm/types/events-mapbox';
import { LayersList } from '@deck.gl/core/typed';
import { styled } from '@mui/material';
import { LocationPin } from '@styled-icons/entypo';
import { Position } from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import DeckGLOverlay from './utils/DeckOverlay';
import { MAP_STYLES } from './utils/map.config';

export type MapStyle = 'default' | 'satellite';

export interface IMapPopup {
  content: ReactNode;
  coordinate: [number, number];
  id?: string;
}

/**
 * Only expose the properties that are really needed and used for the component
 */
export interface ICustomMapProps
  extends Pick<
      ComponentProps<typeof MapGL>,
      'style' | 'initialViewState' | 'longitude' | 'latitude' | 'zoom'
    >,
    MapCallbacks {
  /** @default false */
  disableControls?: boolean;
  /** You can pass a set of deck gl layer that will be rendered on top of the map */
  layers?: LayersList | undefined;
  mapStyle?: MapStyle;
  /** An array of lat, lng position where a marker will be placed on the map
   * Warning this are x, y coordinates [lng, lat]
   * The marker is currently always draggable
   */
  marker?: Position;
  onMapLoad?: (ref: MapEvent['target']) => void;
  /** @default false */
  onMarkerDragEnd?: (event: MarkerDragEvent) => void;
}

const StyledLocationPin = styled(LocationPin)(({ theme }) => ({
  color: theme.palette.primary.main
}));

const StyledMap = styled(MapGL)(({ theme }) => ({
  borderRadius: theme.spacing(0.5)
}));

const StyledNavigationControl = styled(NavigationControl)({
  right: 10,
  top: 10
});

export const CustomMap: FC<ICustomMapProps> = ({
  disableControls,
  layers,
  mapStyle = 'default',
  marker,
  onMapLoad,
  onMarkerDragEnd,
  ...props
}) => {
  const [isCursorHoveringLayer, setIsCursorHoveringLayer] =
    useState<boolean>(false);

  /**
   * Workaround to fix the reference not being updated when the ref is passed properly
   * This is a known issue with react-map-gl in some situation but it is still unclear why it work for some of our components
   * https://stackoverflow.com/questions/71224100/react-ref-current-is-still-null-in-componentdidupdate
   * Get rid of this when the issue is fixed and use the ref directly
   */
  const updateMapRef = useCallback(
    (event: MapEvent) => onMapLoad?.(event.target),
    [onMapLoad]
  );

  /**
   * Handle the cursor when hovering a layer
   * Warning: This is a extremely performance heavy function
   */
  const handleCursorHoveringLayer = useCallback(
    (event: { isHovering: boolean }) => {
      setIsCursorHoveringLayer(event.isHovering);
      return event.isHovering ? 'grab' : 'pointer';
    },
    [setIsCursorHoveringLayer]
  );

  return (
    <StyledMap
      mapboxAccessToken={process.env.REACT_APP_MAP_BOX_TOKEN}
      mapLib={mapboxgl}
      mapStyle={MAP_STYLES[mapStyle]}
      {...props}
      cursor={isCursorHoveringLayer ? 'pointer' : undefined}
      onLoad={updateMapRef}
    >
      {layers && (
        <DeckGLOverlay getCursor={handleCursorHoveringLayer} layers={layers} />
      )}
      {!disableControls && <StyledNavigationControl showCompass={false} />}
      {marker?.length === 2 && (
        <Marker
          draggable
          anchor="bottom"
          latitude={marker[1]}
          longitude={marker[0]}
          onDragEnd={onMarkerDragEnd}
        >
          <StyledLocationPin size={50} />
        </Marker>
      )}
    </StyledMap>
  );
};

export default CustomMap;
