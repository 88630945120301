import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Container, Draggable } from 'react-smooth-dnd';
import {
  Button,
  FormControl,
  Grid,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { EMPTY_MULTI_LANG_FIELD } from 'constants/Language';
import languages from 'constants/languages';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import {
  // GripVertical,
  XCircleFill,
} from 'styles/icons';
import {
  ImpactCatalogItemMultiLang,
  MultiLanguageString,
} from 'types/types.types';
import { arrayMove } from 'utils/general';
import { getLanguageIndex, getLanguageValue } from 'utils/translations';
import {
  DeleteRow,
  ListItemActions,
} from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/styles';

interface Props {
  field: keyof ImpactCatalogItemMultiLang;
}

const EMPTY_ITEM = {
  description: EMPTY_MULTI_LANG_FIELD,
};

const MultiLangListField: React.FC<Props & TextFieldProps> = ({
  field,
  ...rest
}: Props & TextFieldProps) => {
  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext<ImpactCatalogItemMultiLang>();

  const list = getValues(field) as any as {
    description: MultiLanguageString;
  }[];

  const value = useWatch({ control, name: field });

  const moveCard: (props: {
    addedIndex: number | null;
    removedIndex: number | null;
  }) => void = useCallback(
    ({ addedIndex, removedIndex }) => {
      if (removedIndex === null || addedIndex === null) return;
      const reordered = arrayMove(
        cloneDeep(getValues(field)) as any[],
        removedIndex,
        addedIndex
      );
      setValue(field, reordered);
    },
    [value]
  );

  const deleteRow = useCallback(
    (itemIndex: number) => {
      const listCopy = cloneDeep(getValues(field)) as any;
      listCopy.splice(itemIndex, 1);

      setValue(field, listCopy);
    },
    [value]
  );

  const addRow = useCallback(() => {
    const listCopy = cloneDeep(getValues(field)) as any;
    listCopy.push(EMPTY_ITEM);
    setValue(field, listCopy);
  }, []);

  const fieldLabel = startCase(field);

  return (
    <Grid container>
      <Grid item xs={12}>
        {/* The library is deprecated and has support for the latest react version*/}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Container
          dragHandleSelector=".drag-handle"
          groupName={field}
          onDrop={moveCard}
        >
          <>
            {list.map(({ description }, index) => (
              // The library is deprecated and has support for the latest react version
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              <Draggable key={field + index}>
                <Box padding={1}>
                  <Grid container spacing={2}>
                    {languages.map(({ short: lang }) => (
                      <Grid
                        item
                        key={lang}
                        md={(12 - 1) / languages.length}
                        xs={12}
                      >
                        <FormControl
                          fullWidth
                          error={
                            !!(errors as any)[
                              `${field}[${index}].description[${getLanguageIndex(
                                description,
                                lang
                              )}].value` as any
                            ]
                          }
                        >
                          <TextField
                            {...register(
                              `${field}[${index}].description[${getLanguageIndex(
                                description,
                                lang
                              )}].value` as any
                            )}
                            fullWidth
                            defaultValue={getLanguageValue(
                              list[index].description,
                              lang
                            )}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{ style: { pointerEvents: 'all' } }}
                            label={`${fieldLabel} ${index + 1} (${lang})`}
                            variant="outlined"
                            {...rest}
                          />
                        </FormControl>
                      </Grid>
                    ))}
                    {list.length > 0 && (
                      <ListItemActions item>
                        {/* {list.length > 1 && (
                        <DragIcon className="drag-handle">
                          <Tooltip title="Move">
                            <GripVertical size={14} />
                          </Tooltip>
                        </DragIcon>
                      )} */}
                        <DeleteRow onClick={() => deleteRow(index)}>
                          <Tooltip title="Delete">
                            <XCircleFill size={15} />
                          </Tooltip>
                        </DeleteRow>
                      </ListItemActions>
                    )}
                  </Grid>
                </Box>
              </Draggable>
            ))}
          </>
        </Container>
      </Grid>
      {list.filter(({ description }) => !description).length < 1 && (
        <Grid item xs={12}>
          <Button onClick={addRow}>Add {fieldLabel}</Button>
        </Grid>
      )}
    </Grid>
  );
};

export default MultiLangListField;
