import React from 'react';
import { Avatar, Box, styled, Typography } from '@mui/material';
import { Product } from 'types/companies.types';
import { getProductStatusColor } from 'utils/statusUtils';

interface Props {
  isSelected: boolean;
  onClick?: () => void;
  product: Product;
}

const AVATAR_SIZE = 25;

export const ProductListItemWrapper = styled('div')<{
  $isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid
    ${({ $isSelected, theme }) =>
      $isSelected ? theme.palette.primary.dark : theme.palette.divider};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
`;

const ProductListItem: React.FC<Props> = ({ isSelected, onClick, product }) => {
  return (
    <ProductListItemWrapper $isSelected={isSelected} onClick={onClick}>
      <Avatar
        src={product.imageUrl}
        sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
        variant="square"
      />
      <Box display="flex" flexDirection="column">
        <Typography ml={2} variant="body2">
          {product.title}
        </Typography>
        <Typography
          color={getProductStatusColor(product.status)}
          ml={2}
          variant="caption"
        >
          {product.status}
        </Typography>
      </Box>
    </ProductListItemWrapper>
  );
};

export default ProductListItem;
