import React from 'react';
import {
  Box,
  TableHead as MuiTableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { OrderType } from 'types/types.types';
import { HeadCell } from 'components/Table/Table';
import StyledHeaderTableCell from 'components/Table/TableHeaderCell/TableHeaderCell';

interface Props<T extends Record<string, unknown>> {
  headCells: HeadCell<T>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: OrderType;
  orderBy: keyof T;
}

const TableHead = <T extends Record<string, unknown>>(props: Props<T>) => {
  const { onRequestSort, order, orderBy } = props;
  const createSortHandler =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <MuiTableHead>
      <TableRow>
        {props.headCells.map(headCell => (
          <StyledHeaderTableCell
            align={'left'}
            key={headCell.id as string}
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledHeaderTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableHead;
