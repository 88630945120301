import { updateLanguageFields } from 'graphql/utils';
import { MultiLanguageString } from 'types/types.types';

const impactClaimPolicies = {
  AdminImpactCatalogItem: {
    fields: {
      title: {
        read(title: MultiLanguageString) {
          return updateLanguageFields(title);
        },
      },
      definition: {
        read(description: MultiLanguageString) {
          return updateLanguageFields(description);
        },
      },
      acceptanceCriteria: {
        read(items: { description: MultiLanguageString }[]) {
          return items.map(({ description }) => {
            return { description: updateLanguageFields(description) };
          });
        },
      },
      docRequirements: {
        read(items: { description: MultiLanguageString }[]) {
          return items.map(({ description }) => {
            return { description: updateLanguageFields(description) };
          });
        },
      },
      categoryId: (
        value: null,
        {
          readField,
        }: {
          readField: (
            field: string,
            object?: any
          ) => {
            id: string;
            type: string;
          };
        }
      ) => {
        return readField('id', readField('category')) || '';
      },
      categoryType: (
        value: null,
        {
          readField,
        }: {
          readField: (
            field: string,
            object?: any
          ) => {
            id: string;
            type: string;
          };
        }
      ) => {
        return readField('type', readField('category')) || '';
      },
      sustainableDevGoalsIds: (
        value: null,
        {
          readField,
        }: {
          readField: (field: string, object?: any) => any;
        }
      ) => {
        return ((readField('sustainableDevGoals') as any[]) || []).map(sdg =>
          readField('id', sdg)
        );
      },
    },
  },
};

export default impactClaimPolicies;
