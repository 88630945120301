/**
 * Move an item inside an array from one index to another
 */
export const arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

/**
 * Displays a value if it's present, and otherwise display a default value for it.
 * For example, if the value is undefined we can by default show a '-' mark.
 * @param value
 * @param defaultEmptyValue
 * @returns
 */
export const displayNullableValue = (
  value: string | number | undefined | null,
  defaultEmptyValue = '-'
) => {
  if (value === undefined || value === null) {
    return defaultEmptyValue;
  }

  return value;
};
