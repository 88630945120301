import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Link, Typography } from '@mui/material';
import { ImpactClaimSubmission } from 'types/types.types';
import { formatDateTime } from 'utils/dates';
import { getImpactClaimStatusColor } from 'utils/statusUtils';
import { CompanyAttributeRow } from 'components/Companies/ActiveCompanyPage/styles';

interface Props {
  impactClaimSubmission: ImpactClaimSubmission;
}

const ImpactClaimRow: React.FC<Props> = ({ impactClaimSubmission }) => {
  const navigate = useNavigate();

  return (
    <CompanyAttributeRow>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={4}>
          <Box display="flex" flexDirection="column">
            <Typography>
              {impactClaimSubmission.impactCatalogItem.title}
            </Typography>
            <Typography
              color={getImpactClaimStatusColor(impactClaimSubmission.status)}
              variant="caption"
            >
              {impactClaimSubmission.status}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">Created</Typography>
            <Typography>
              {formatDateTime(impactClaimSubmission.createdTimestamp)}
            </Typography>
          </Box>
        </Grid>
        <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={4}>
          <Link
            color="secondary"
            mr={2}
            sx={{
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            variant="body2"
            onClick={() => {
              navigate(`impact-claims/submissions/${impactClaimSubmission.id}`);
            }}
          >
            View claim
          </Link>
        </Grid>
      </Grid>
    </CompanyAttributeRow>
  );
};

export default ImpactClaimRow;
