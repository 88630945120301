import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_CLAIM_SUBMISSIONS } from 'graphql/queries';
import { ImpactClaimSubmissionResult } from 'types/types.types';

const useSubmissions = () => {
  const navigate = useNavigate();

  const {
    data: { impactClaims: { edges: claimSubmissions = [] } = {} } = {},
    error,
    loading,
  } = useQuery<ImpactClaimSubmissionResult>(GET_CLAIM_SUBMISSIONS, {
    fetchPolicy: 'cache-and-network',
  });

  const handleSubmissionClick = (submissionId: string) => {
    navigate(`/impact-claims/submissions/${submissionId}`);
  };

  return {
    claimSubmissions: claimSubmissions.map(({ node }) => node),
    error,
    loading,
    handleSubmissionClick,
  };
};

export default useSubmissions;
