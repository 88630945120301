import React, { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Container, Draggable } from 'react-smooth-dnd';
import {
  Button,
  FormControl,
  Grid,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import {
  // GripVertical,
  XCircleFill,
} from 'styles/icons';
import { ImpactCatalogItemMultiLang } from 'types/types.types';
import { arrayMove } from 'utils/general';
import {
  DeleteRow,
  // DragIcon,
  ListItemActions,
} from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/styles';

interface Props {
  field: keyof ImpactCatalogItemMultiLang;
}

const ListField: React.FC<Props & TextFieldProps> = ({
  field,
  ...rest
}: Props & TextFieldProps) => {
  const {
    control,
    formState: { errors },
    getValues,
    register,
    setValue,
  } = useFormContext<ImpactCatalogItemMultiLang>();
  const watchValue = useWatch({ control, name: field });

  const list = getValues(field) as string[];

  const moveCard: (props: {
    addedIndex: number | null;
    removedIndex: number | null;
  }) => void = useCallback(
    ({ addedIndex, removedIndex }) => {
      if (removedIndex === null || addedIndex === null) return;
      const reordered = arrayMove(
        cloneDeep(getValues(field)) as any[],
        removedIndex,
        addedIndex
      );
      setValue(field, reordered);
    },
    [watchValue]
  );

  const deleteRow = useCallback(
    (itemIndex: number) => {
      const listCopy = cloneDeep(getValues(field)) as any;
      listCopy.splice(itemIndex, 1);

      setValue(field, listCopy);
    },
    [watchValue]
  );

  const addRow = useCallback(() => {
    const listCopy = cloneDeep(getValues(field)) as any;
    listCopy.push('');
    setValue(field, listCopy);
  }, []);

  const fieldLabel = startCase(field);

  return (
    <Grid container direction="column">
      {/* The library is deprecated and has support for the latest react version*/}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Container
        dragHandleSelector=".drag-handle"
        groupName={field}
        onDrop={moveCard}
      >
        {list.map((value, index) => (
          // The library is deprecated and has support for the latest react version
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Draggable key={index}>
            <Box padding={1}>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <FormControl fullWidth error={!!errors[`${field}`]}>
                    <TextField
                      {...register(`${field}[${index}]` as any, {
                        required: true,
                      })}
                      fullWidth
                      defaultValue={list[index]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ style: { pointerEvents: 'all' } }}
                      label={`${fieldLabel} ${index + 1}`}
                      variant="outlined"
                      {...rest}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  {list.length > 0 && (
                    <ListItemActions item>
                      {/* {list.length > 1 && (
                        <DragIcon className="drag-handle">
                          <Tooltip title="Move">
                            <GripVertical size={14} />
                          </Tooltip>
                        </DragIcon>
                      )} */}
                      <DeleteRow onClick={() => deleteRow(index)}>
                        <Tooltip title="Delete">
                          <XCircleFill size={15} />
                        </Tooltip>
                      </DeleteRow>
                    </ListItemActions>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Draggable>
        ))}
      </Container>
      {list.filter(x => !x).length < 1 && (
        <Grid item xs={2}>
          <Button onClick={addRow}>Add {fieldLabel}</Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ListField;
