import styled from 'styled-components';

export const PublishedIndicator = styled.div`
  width: 20px;
  height: 2px;
`;

export const GreenIndicator = styled(PublishedIndicator)`
  background-color: ${({ theme }) => theme.palette.success.light};
`;
export const OrangeIndicator = styled(PublishedIndicator)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
`;
