import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_ANALYTICS } from 'graphql/queries/analytics';
import { CompanyAnalyticsResult } from 'types/analytics.types';
import { getDateMonthsFromNow } from 'utils/dates';

export const useCompanyAnalytics = (companyId: string) => {
  // Always start the data 3 months from now.
  const startDate: string = useMemo(() => {
    return getDateMonthsFromNow(3);
  }, []);

  return useQuery<CompanyAnalyticsResult>(GET_COMPANY_ANALYTICS, {
    onError: () => {
      toast('Something went wrong while fetching the company analytics data.');
    },
    variables: {
      params: {
        companyId,
        startDate,
      },
    },
  });
};
