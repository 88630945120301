import React, { Fragment, memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { Trash } from 'styles/icons';
import FileUpload from 'components/FileUpload/FileUpload';
import {
  ErrorText,
  IconImage,
  RemoveImageButton,
  UploadImageContainer
} from './styles';

interface Props {
  buttonText: string;
  imageUrl?: string;
  label?: string;
  onAddImage: (url: string) => void;
  onRemoveImage: () => void;
  showRequiredError?: boolean;
  targetFolder?: string;
}

const removeImage = (imageUrl: string) => {
  // Checks if the url points to firebase and one of our storage spaces
  // If not, the url is pointing to an external source which we won't try to delete :)
  const canBeDeleted: boolean =
    imageUrl.includes('firebasestorage.googleapis') &&
    imageUrl.includes('seedtrace');

  if (canBeDeleted) {
    const storage = getStorage();
    // Create a reference from an HTTPS URL
    const httpsReference = ref(storage, imageUrl);
    // Delete the file
    deleteObject(httpsReference)
      .then(() => {
        // File deleted successfully
        toast.success('Deleted icon succesfully');
      })
      .catch(error => {
        console.error(error);
        toast.error(
          'Something went wrong while deleting the icon from firebase.'
        );
      });
  }
};

const ImageUploadField: React.FC<Props> = ({
  buttonText,
  imageUrl,
  label,
  onAddImage,
  onRemoveImage,
  showRequiredError = false,
  targetFolder
}) => {
  const [fileUploadUrl, setFileUploadUrl] = useState<string | undefined>();

  useEffect(() => {
    setFileUploadUrl(imageUrl);
  }, [imageUrl]);

  return (
    <div>
      {fileUploadUrl ? (
        <Fragment>
          {label && (
            <Typography gutterBottom fontWeight={700} variant="body2">
              {label}
            </Typography>
          )}
          <UploadImageContainer>
            <IconImage src={fileUploadUrl} />
            <RemoveImageButton
              onClick={() => {
                removeImage(fileUploadUrl);
                onRemoveImage();
              }}
            >
              <Trash size={15} />
            </RemoveImageButton>
          </UploadImageContainer>
        </Fragment>
      ) : (
        <FileUpload
          buttonText={buttonText}
          targetFolder={targetFolder}
          onFileUploadComplete={(url: string) => {
            onAddImage(url);
          }}
        />
      )}
      {showRequiredError && <ErrorText variant="body2">*required </ErrorText>}
    </div>
  );
};

export default memo(ImageUploadField);
