import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import languages from 'constants/languages';
import { IRawMaterialMultiLang } from 'types/rawMaterials.types';
import MultiLangTextField from 'components/FormParts/MultiLangTextField';

interface IMultiLangFields {
  initialRawMaterial: IRawMaterialMultiLang;
}

const MultiLangFields: FC<IMultiLangFields> = ({
  initialRawMaterial,
}: IMultiLangFields) => {
  const { formState } = useFormContext<IRawMaterialMultiLang>();
  const { errors } = formState;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {languages.map(({ full: lang }) => (
            <Grid item key={lang} md={(12 - 1) / languages.length} xs={0}>
              <b>{lang}</b>
            </Grid>
          ))}
        </Grid>

        <Grid container direction="column" mt={2} spacing={1}>
          <Grid item>
            <MultiLangTextField
              error={!!errors['title']}
              field="title"
              languageArray={initialRawMaterial.title}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MultiLangFields;
