import { updateLanguageFields } from 'graphql/utils';
import { MultiLanguageString } from 'types/types.types';

const certificationPolicies = {
  AdminCertificateCatalogItem: {
    fields: {
      title: {
        read(title: MultiLanguageString) {
          return updateLanguageFields(title);
        },
      },
      description: {
        read(description: MultiLanguageString) {
          return updateLanguageFields(description);
        },
      },
    },
  },
};

export default certificationPolicies;
