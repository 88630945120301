import React from 'react';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import styled, { useTheme } from 'styled-components';
import { LogOut } from 'styles/icons';
import useAuth from 'components/Auth/hooks/useAuth';
import { useMenuItems } from './hooks';
import MenuItem from './MenuItem';

const DrawerStyled = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${({ theme }) => theme.palette.primary.main};
    color: #fff;
  }

  .Mui-selected {
  }
`;

const StyledDivider = styled(Divider)`
  border-color: rgba(255, 255, 255, 0.1);
`;

interface Props {
  drawerWidth: number;
}

const Menu: React.FC<Props> = ({ drawerWidth }: Props) => {
  const { menuItems } = useMenuItems();
  const { onLogout } = useAuth();
  const theme = useTheme();

  return (
    <DrawerStyled
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        height: '100vh',
        background: theme.palette.background.default,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box'
        }
      }}
      variant="permanent"
    >
      <Toolbar>Seedtrace Admin</Toolbar>
      <StyledDivider />
      <List>
        {menuItems.map(({ icon: Icon, subMenu, text }) => {
          return (
            <MenuItem icon={Icon} key={text} subMenu={subMenu} text={text} />
          );
        })}
      </List>
      <StyledDivider />

      <List>
        <ListItemButton onClick={onLogout}>
          <ListItemIcon sx={{ minWidth: 30 }}>
            <LogOut color="#fff" size={16} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </DrawerStyled>
  );
};
export default Menu;
