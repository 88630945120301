import React, { useEffect, useState } from 'react';
import {
  ApolloClient,
  ApolloProvider as OriginalApolloProvider,
  NormalizedCacheObject
} from '@apollo/client';
import initializeApolloClient from './client';

interface ProviderProps {
  children: React.ReactChild;
  graphqlEndpoint?: string;
}

const ApolloProvider = ({ children, graphqlEndpoint }: ProviderProps) => {
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();
  const hasClient = !!apolloClient;

  useEffect(() => {
    if (hasClient) return;

    async function init() {
      if (!graphqlEndpoint) {
        console.error('REACT_APP_GQL_ENDPOINT is missing in your .env file');
        return;
      }
      // wait for store to by hydrated from localStorage
      const client = await initializeApolloClient({
        uri: graphqlEndpoint
      });
      setApolloClient(client);
    }
    init();
  }, [hasClient]); // eslint-disable-line

  if (!apolloClient) return null;

  return (
    <OriginalApolloProvider client={apolloClient}>
      {children}
    </OriginalApolloProvider>
  );
};

export default ApolloProvider;
