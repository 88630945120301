import { useQuery } from '@apollo/client';
import { GET_FEATURE_LIST } from 'graphql/queries/features';
import { FeaturesFetchResult } from 'types/companies.types';

export const useFeatureList = () => {
  const { data, error, loading } = useQuery<FeaturesFetchResult>(
    GET_FEATURE_LIST,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    companyFeatures: data?.companyFeatures,
    error,
    loading,
  };
};
