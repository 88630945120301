import { ApolloError, useQuery } from '@apollo/client';
import { GET_SINGLE_COMPANY_AND_PRODUCTS } from 'graphql/queries/companies';
import {
  CompanyUser,
  ExtendedCompany,
  ImpactClaimKpis,
  Product,
  ProductKpis,
  SingleCompanyFetchResult,
  UserKpis,
} from 'types/companies.types';
import { ImpactClaimSubmission } from 'types/types.types';

export const useSingleCompany = (
  companyId: string
): {
  company: ExtendedCompany | undefined;
  error: ApolloError | undefined;
  impactClaimKpis: ImpactClaimKpis | undefined;
  loading: boolean;
  productKpis: ProductKpis | undefined;
  userKpis: UserKpis | undefined;
} => {
  const { data, error, loading } = useQuery<SingleCompanyFetchResult>(
    GET_SINGLE_COMPANY_AND_PRODUCTS,
    {
      variables: {
        id: companyId,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  if (data?.company === undefined) {
    return {
      company: undefined,
      productKpis: undefined,
      impactClaimKpis: undefined,
      userKpis: undefined,
      error,
      loading,
    };
  }

  const { impactClaims, products, users, ...company } = data.company;

  const allProducts: Product[] = products.edges.map(({ node }) => node) || [];
  const allImpactClaims: ImpactClaimSubmission[] =
    impactClaims.edges.map(({ node }) => node) || [];
  const allUsers: CompanyUser[] = users.edges.map(({ node }) => node) || [];

  const extendedCompany: ExtendedCompany = {
    ...company,
    products: allProducts,
    impactClaims: allImpactClaims,
    users: allUsers,
  };

  return {
    company: extendedCompany,
    productKpis: products.kpis,
    impactClaimKpis: impactClaims.kpis,
    userKpis: users.kpis,

    error,
    loading,
  };
};
