import { EMPTY_MULTI_LANG_FIELD } from 'constants/Language';
import xorBy from 'lodash/xorBy';
import {
  CertificateCatalogItemStatus,
  CertificationCatalogItemMultiLang,
} from 'types/types.types';

/**
 * Function which takes in the initial certification, then checks if all the language values are in the multiLangFields ( title, description )
 * If it finds fields which are not present, it will get the defaultValues for these languages and add them to their respective arrays.
 * Returns a updated initialCertification with the correct language fields ( otherwise the form does not know which indexes to update )
 * -> If the backend returns this consistently we would not need to do this trick.
 * @param initialCertification
 * @returns
 */
export const updateInitialCertificationWithMultiLangFields = (
  initialCertification: CertificationCatalogItemMultiLang
) => {
  // If the initialCertification only has a description which contains [{lang: en}], this will get the defaultValues for fr and de.
  const languagesToAdd = xorBy(
    EMPTY_MULTI_LANG_FIELD,
    initialCertification.description,
    'language'
  );

  // The languages are added to the initial certification.
  return {
    ...initialCertification,
    description: [...initialCertification.description, ...languagesToAdd],
    title: [...initialCertification.title, ...languagesToAdd],
  };
};

export const getAvailableStatusUpdates = (
  status?: CertificateCatalogItemStatus
): Array<CertificateCatalogItemStatus> => {
  switch (status) {
    case 'REQUESTED':
      return ['APPROVED', 'DENIED'];
    case 'APPROVED':
      return ['DENIED'];
    case 'DENIED':
      return ['APPROVED'];
    default:
      return [];
  }
};
