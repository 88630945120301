import React from 'react';
import { Typography } from '@mui/material';
import { AnalyticsSummaryItem } from 'types/analytics.types';
import { formatMilisecondsToReadableTime } from 'utils/dates';
import { MetricBlockWrapper, MetricValueText } from './styles';

interface Props {
  summaryItem: AnalyticsSummaryItem;
  title: string | number;
  variant?: 'TIME' | 'TEXT';
}

const MetricBlock: React.FC<Props> = ({
  summaryItem,
  title,
  variant = 'TEXT',
}) => {
  const isPositive: boolean = summaryItem.percentageChange >= 0;
  return (
    <MetricBlockWrapper>
      <MetricValueText textAlign={'center'} variant="h5">
        {variant === 'TIME'
          ? formatMilisecondsToReadableTime(summaryItem.current)
          : summaryItem.current}
      </MetricValueText>
      <Typography textAlign={'center'} variant="body2">
        {title}
      </Typography>
      <Typography textAlign={'center'} variant="caption">
        <span style={{ color: isPositive ? 'green' : 'red' }}>
          {`${isPositive ? '+' : ''} ${summaryItem.percentageChange.toFixed(
            1
          )}% `}
        </span>
        vs previous 3 months
      </Typography>
    </MetricBlockWrapper>
  );
};

export default MetricBlock;
