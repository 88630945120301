import { TabPanel } from '@mui/lab';
import { Box, Checkbox, Grid } from '@mui/material';
import styled from 'styled-components';

export const StyledContainer = styled(Box)`
  background: #fff;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(3)};
`;

export const StyledGrid = styled(Grid)`
  flex-wrap: wrap;
  flex-direction: row;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

export const StyledTabPanel = styled(TabPanel)`
  padding: 16px 0px;
`;

export const CompanyAttributeRow = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 5px;
  &:first-of-type {
    margin-top: 0;
  }
`;
