import { RAW_MATERIAL_FRAGMENT } from 'graphql/fragments';
import gql from 'graphql-tag';

export const GET_RAW_MATERIALS = gql`
  query GetRawMaterials {
    rawMaterials {
      edges {
        node {
          ...rawMaterialValues
        }
      }
    }
  }
  ${RAW_MATERIAL_FRAGMENT}
`;
