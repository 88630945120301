import { IconButton, Typography } from '@mui/material';
import styled from 'styled-components';

export const UploadImageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const IconImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: 10px;
`;

export const RemoveImageButton = styled(IconButton)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(-1)};
  right: ${({ theme }) => theme.spacing(-1)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #fff;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const ErrorText = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`;
