import { ImpactCatalogItemMultiLang } from 'types/types.types';
import { EMPTY_MULTI_LANG_FIELD } from './Language';

export const EMPTY_CLAIM: ImpactCatalogItemMultiLang = {
  id: '',
  types: ['CLAIM', 'PROOF_POINT'],
  links: [],
  thirdParties: [],
  title: EMPTY_MULTI_LANG_FIELD,
  definition: EMPTY_MULTI_LANG_FIELD,
  category: {
    id: '',
    type: 'ECOLOGICAL',
  },
  categoryId: '',
  categoryType: 'ECOLOGICAL',
  sustainableDevGoals: [],
  sustainableDevGoalsIds: [],
  acceptanceCriteria: [],
  docRequirements: [],
};
