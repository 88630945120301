import React, { useMemo } from 'react';
import { ScatterplotLayer } from '@deck.gl/layers/typed';
import { useTheme } from '@mui/material';
import CustomMap from 'designSystem/Map/CustomMap';
import { GeoAnalyticItem } from 'types/analytics.types';
import { convertHexToRGBarray } from 'utils/colorUtils';

const INITIAL_VIEW_STATE = {
  longitude: 13,
  latitude: 52,
  zoom: 0,
  maxZoom: 16,
  pitch: 0,
  bearing: 0,
};

interface Props {
  geoAnalytics: GeoAnalyticItem[];
}

const AnalyticsMap: React.FC<Props> = ({ geoAnalytics }) => {
  const theme = useTheme();
  const fillColor = convertHexToRGBarray(theme.palette.secondary.main);

  const layers = useMemo(
    () => [
      new ScatterplotLayer({
        id: 'main-map',
        data: geoAnalytics,
        getPosition: (d: any) => [d.lng, d.lat],
        getRadius: (d: any) => Math.sqrt(d.weight / 100),
        getFillColor: () => fillColor,
        radiusUnits: 'pixels',
        opacity: 0.4,
        filled: true,
        radiusMaxPixels: 30,
        radiusMinPixels: 1,
        radiusScale: 5,
      }),
    ],
    [geoAnalytics]
  );

  return (
    <div style={{ width: '100%', height: 350, position: 'relative' }}>
      <CustomMap initialViewState={INITIAL_VIEW_STATE} layers={[layers]} />
    </div>
  );
};

export default AnalyticsMap;
