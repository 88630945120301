import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { CHANGE_CLAIM_SUBMISSION_STATUS } from 'graphql/mutations/impactClaims';
import { GET_SINGLE_CLAIM_SUBMISSION } from 'graphql/queries';
import { ChangeClaimSubmissionStatusPayload } from 'types/mutations.types';
import {
  GraphQlConnection,
  ImpactClaim,
  ImpactClaimLog
} from 'types/types.types';

interface Props {
  id?: string;
}

const useSingleSubmission = ({ id }: Props) => {
  const [changeStatus] = useMutation<ChangeClaimSubmissionStatusPayload>(
    CHANGE_CLAIM_SUBMISSION_STATUS,
    {
      onCompleted: () => {
        toast.success('Success! Your details were updated.');
      },
      onError: () => {
        toast.error('Something went wrong updating your details.');
      }
    }
  );

  const {
    data: { impactClaim: claimSubmission, impactClaimHistory } = {},
    error,
    loading
  } = useQuery<{
    impactClaim: ImpactClaim;
    impactClaimHistory: GraphQlConnection<ImpactClaimLog>;
  }>(GET_SINGLE_CLAIM_SUBMISSION, {
    variables: {
      lang: 'en',
      id
    }
  });

  const handleApprove = () => {
    changeStatus({ variables: { id, input: { status: 'APPROVED' } } });
  };

  const handleReject = ({ reason }: { reason: string }) => {
    changeStatus({
      variables: { id, input: { status: 'DENIED', comment: reason } }
    });
  };

  return {
    claimSubmission,
    impactClaimHistory: impactClaimHistory?.edges.map(({ node }) => node) || [],
    error,
    loading,
    handleReject,
    handleApprove
  };
};

export default useSingleSubmission;
