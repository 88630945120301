import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

interface Props {
  cancelText?: string;
  children?: React.ReactChild;
  confirmText?: string;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
  title?: string;
}

const ConfirmDialog: React.FC<Props> = ({
  cancelText = 'Cancel',
  children,
  confirmText = 'Yes',
  onClose,
  onConfirm,
  open,
  title = 'Are you sure?'
}: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          {cancelText}
        </Button>
        <Button onClick={onConfirm}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
