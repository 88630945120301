import { EMPTY_MULTI_LANG_FIELD } from 'constants/Language';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import { MultiLanguageString } from 'types/types.types';

export const transformVariableTypes = (variables: any, serializers: any) => {
  return transform(variables, (result: any, value, key) => {
    const object = isObject(value);
    const array = isArray(value);
    const serializer = (val: any) =>
      val?.__typename && serializers[val.__typename];

    if (object && serializer(value)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = serializer(value)(value);
    } else if (array) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value.map(item => {
        if (!isObject(item) && !isArray(item)) return item;
        else if (isObject(item) && serializer(item)) {
          // eslint-disable-next-line no-param-reassign
          item = serializer(item)(item);
        }

        return transformVariableTypes(item, serializers);
      });
    } else if (object) {
      // eslint-disable-next-line no-param-reassign
      result[key] = transformVariableTypes(value, serializers);
    } else {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    }
  });
};

/**
 * Ensures that all the languages are present in the MultiLanguageString.
 * If the backend only returns {language: 'fr', value:'test'} then this function adds empty fields for 'de' and 'en' for example.
 * After that function returns an array with all the present values.
 * @param field
 * @returns
 */
export const updateLanguageFields = (field: MultiLanguageString) => {
  const updatedLanguages = EMPTY_MULTI_LANG_FIELD.map(lang => {
    const matchingLanguage = field.find(
      language => lang.language === language.language
    );

    return {
      ...lang,
      ...matchingLanguage,
    };
  });

  return updatedLanguages;
};
