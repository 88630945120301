import { matchPath } from 'react-router-dom';
import { FileCheck } from '@styled-icons/bootstrap';
import { Component } from '@styled-icons/boxicons-solid/Component';
import { BarChart } from 'styled-icons/boxicons-regular';
import { StyledIcon } from 'styled-icons/types';
import { Building, CheckCircle, Tags } from 'styles/icons';

interface MenuItem {
  icon?: StyledIcon;
  overViewPage: { text: string; url: string };
  text: string;
  url: string;
}

interface MenuCategories {
  icon: StyledIcon;
  subMenu: MenuItem[];
  text: string;
}

const CLAIMS_MENU_ITEMS: MenuItem[] = [
  {
    text: 'Submissions',
    url: '/impact-claims/submissions',
    icon: CheckCircle,
    overViewPage: {
      text: 'Submission',
      url: 'impact-claims/submissions/:submissionId'
    }
  },
  {
    text: 'Catalog',
    url: '/impact-claims',
    icon: Tags,
    overViewPage: {
      text: 'Impact claim',
      url: '/impact-claims/:claimId'
    }
  }
];

const COMPANIES_MENU_ITEMS: MenuItem[] = [
  {
    text: 'Companies',
    url: '/companies',
    icon: Building,
    overViewPage: {
      text: 'Company',
      url: '/companies/:companyId'
    }
  }
];

const ANALYTICS_MENU_ITEMS: MenuItem[] = [
  {
    text: 'Analytics',
    url: '/analytics',
    icon: BarChart,
    overViewPage: {
      text: 'Analytics',
      url: '/analytics'
    }
  }
];

const CERTIFICATION_MENU_ITEMS: MenuItem[] = [
  {
    text: 'Requests',
    url: '/certifications/requests',
    icon: CheckCircle,
    overViewPage: {
      text: 'Submission',
      url: 'certifications/requests/:certificationId'
    }
  },
  {
    text: 'Catalog',
    url: '/certifications',
    icon: Tags,
    overViewPage: {
      text: 'Certification',
      url: '/certifications/:certificationId'
    }
  }
];

const RAW_MATERIALS_MENU_ITEMS: MenuItem[] = [
  {
    text: 'Requests',
    url: '/raw-materials/requests',
    icon: CheckCircle,
    overViewPage: {
      text: 'Submission',
      url: 'raw-materials/requests/:certificationId'
    }
  },
  {
    text: 'Catalog',
    url: '/raw-materials',
    icon: Tags,
    overViewPage: {
      text: 'Raw Material',
      url: '/raw-materials/:certificationId'
    }
  }
];

const CATEGORIES: MenuCategories[] = [
  {
    text: 'Impact claims',
    icon: Tags,
    subMenu: CLAIMS_MENU_ITEMS
  },
  {
    text: 'Certifications',
    icon: FileCheck,
    subMenu: CERTIFICATION_MENU_ITEMS
  },
  {
    text: 'Raw materials',
    icon: Component,
    subMenu: RAW_MATERIALS_MENU_ITEMS
  },
  {
    text: 'Companies',
    icon: Building,
    subMenu: COMPANIES_MENU_ITEMS
  },
  {
    text: 'Analytics',
    icon: BarChart,
    subMenu: ANALYTICS_MENU_ITEMS
  }
];

const useMenuItems = () => {
  const getMenuItemByUrl = (url: string) => {
    const menuItems = [
      ...CLAIMS_MENU_ITEMS,
      ...CERTIFICATION_MENU_ITEMS,
      ...COMPANIES_MENU_ITEMS,
      ...ANALYTICS_MENU_ITEMS
    ];
    const subItemMatch = menuItems.find(item =>
      matchPath(item.overViewPage.url, url)
    );
    if (subItemMatch) return subItemMatch.overViewPage;

    return menuItems.find(item => matchPath(url, item.url));
  };

  return { getMenuItemByUrl, menuItems: CATEGORIES };
};

export default useMenuItems;
