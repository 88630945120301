import { gql } from '@apollo/client';
import { RAW_MATERIAL_FRAGMENT } from 'graphql/fragments';

export const UPDATE_RAW_MATERIAL = gql`
  mutation UpdateRawMaterial($id: UUID!, $input: UpdateRawMaterialInput!) {
    updateRawMaterial(id: $id, input: $input) {
      rawMaterial {
        ...rawMaterialValues
      }
    }
  }
  ${RAW_MATERIAL_FRAGMENT}
`;

export const CREATE_RAW_MATERIAL = gql`
  mutation CreateRawMaterial($input: CreateRawMaterialInput!) {
    createRawMaterial(input: $input) {
      rawMaterial {
        ...rawMaterialValues
      }
    }
  }
  ${RAW_MATERIAL_FRAGMENT}
`;
