import { gql } from '@apollo/client';
import { ADMIN_COMPANY_FRAGMENT } from 'graphql/fragments/company';

export const UPDATE_COMPANY_PLAN = gql`
  mutation UpdateCompanyPlan($id: UUID!, $input: UpdateCompanyPlanInput!) {
    updateCompanyPlan(id: $id, input: $input) {
      company {
        ...adminCompanyValues
      }
    }
  }
  ${ADMIN_COMPANY_FRAGMENT}
`;
