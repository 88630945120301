import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { OrderType } from 'types/types.types';
import { getComparator } from 'utils/table';
import TableHead from 'components/Table/TableHead/TableHead';

export interface HeadCell<T extends Record<string, unknown>> {
  /** can be the direct keyof the row object: `title`
   * or a nested key like: `title.en` */
  id: keyof T | string;
  label: string;
}

interface Props<T extends Record<string, unknown>> {
  children: (row: T) => React.ReactChild;
  defaultOrderType?: OrderType;
  defaultRowsPerPage?: number;
  defaultSorting: keyof T | string;
  headCells: HeadCell<T>[];
  /* Controlled prop */
  onPageChange?: (page: number) => void;
  /* Controlled prop */
  onPageSizeChange?: (pageSize: number) => void;
  onRowClick?: (rowId: string) => void;
  /* Controlled prop */
  page?: number;
  /* Controlled prop */
  pageSize?: number;
  rows: T[];
}

const Table = <T extends Record<string, unknown>>({
  children,
  defaultOrderType = 'asc',
  defaultRowsPerPage = 20,
  defaultSorting,
  headCells,
  onPageChange,
  onPageSizeChange,
  onRowClick,
  page: _page,
  pageSize: _pageSize,
  rows
}: Props<T>) => {
  const [order, setOrder] = useState<OrderType>(defaultOrderType);
  const [orderBy, setOrderBy] = useState<keyof T>(defaultSorting);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

  useEffect(() => setPage(_page || 0), [_page]);
  useEffect(() => setRowsPerPage(_pageSize || 20), [_pageSize]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    onPageChange?.(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onPageSizeChange?.(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    onPageChange?.(0);
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <MuiTable
            aria-labelledby="tableTitle"
            size={'medium'}
            sx={{ minWidth: 750 }}
          >
            <TableHead<T>
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows
                .slice()
                // @ts-expect-error not sure why this complains??
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow
                      hover
                      key={row.id as string}
                      role="checkbox"
                      sx={{
                        ':hover': {
                          cursor: 'pointer'
                        }
                      }}
                      tabIndex={-1}
                      onClick={
                        onRowClick
                          ? () => onRowClick(row.id as string)
                          : undefined
                      }
                    >
                      {children(row)}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  sx={{
                    height: 53 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </TableContainer>
        <TablePagination
          component="div"
          count={rows.length}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 20, 40]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default Table;
