import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import languages from 'constants/languages';
import { ImpactCatalogItemMultiLang } from 'types/types.types';
import MultiLangTextField from 'components/FormParts/MultiLangTextField';
import MultiLangListField from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/MultiLangListField';
import { DottedLine } from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/styles';

interface Props {
  initialImpactClaim: ImpactCatalogItemMultiLang;
}

const MultiLangFields: React.FC<Props> = ({ initialImpactClaim }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<ImpactCatalogItemMultiLang>();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {languages.map(({ full: lang }) => (
            <Grid item key={lang} md={(12 - 1) / languages.length} xs={0}>
              <b>{lang}</b>
            </Grid>
          ))}
        </Grid>

        <Grid container direction="column" mt={2} spacing={1}>
          <Grid item>
            <MultiLangTextField
              error={!!errors['title']}
              field="title"
              languageArray={initialImpactClaim.title}
            />
          </Grid>
          <DottedLine />
          <Grid item>
            <MultiLangTextField
              multiline
              error={!!errors['definition']}
              field="definition"
              languageArray={initialImpactClaim.definition}
            />
          </Grid>
          <DottedLine />
          <Grid item>
            <MultiLangListField multiline field="acceptanceCriteria" />
          </Grid>
          <DottedLine />
          <Grid item>
            <MultiLangListField multiline field="docRequirements" />
          </Grid>
          <DottedLine />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MultiLangFields;
