import {
  ImpactCatalogCategory,
  ImpactCatalogCategoryType,
  ImpactClaimStatusType,
} from 'types/types.types';

/**
 * Get the category id from the type name
 */
export function getCategoryId(
  categoryType: ImpactCatalogCategoryType,
  categories: ImpactCatalogCategory[]
): string | undefined {
  return categories.find(({ type }) => categoryType === type)?.id;
}

/**
 * Get the title matching the impact claim status
 */
export const getStatusTitle = (status: ImpactClaimStatusType) => {
  return {
    CREATED: 'First submitted',
    UPDATED: 'Resubmitted',
    REQUESTED: 'Requested',
    APPROVED: 'Approved',
    DENIED: 'Declined',
    ARCHIVED: 'Archived',
    DELETED: 'Deleted',
  }[status];
};
