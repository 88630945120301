import { ProductStatus } from 'types/companies.types';
import { ImpactClaimStatusType } from 'types/types.types';

export const getProductStatusColor = (status: ProductStatus): string => {
  switch (status) {
    case 'ARCHIVED':
      return 'gray';
    case 'DELETED':
      return 'gray';
    case 'DRAFT':
      return 'orange';
    case 'ERROR':
      return 'red';
    case 'PUBLISHED':
      return 'green';
    default:
      return 'gray';
  }
};

export const getImpactClaimStatusColor = (
  status: ImpactClaimStatusType
): string => {
  switch (status) {
    case 'CREATED':
      return 'green';
    case 'UPDATED':
      return 'orange';
    case 'REQUESTED':
      return 'orange';
    case 'APPROVED':
      return 'green';
    case 'DENIED':
      return 'red';
    case 'DELETED':
      return 'gray';

    default:
      return 'gray';
  }
};
