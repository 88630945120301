import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { ImpactCatalogItemMultiLang } from 'types/types.types';
import ImageUploadField from 'components/FormParts/ImageUploadField';
import ListField from 'components/ImpactClaims/ActiveImpactClaim/BasicFields/ListField';
import { DottedLine } from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/styles';
import ClaimCategory from 'components/ImpactClaims/ClaimCategory/ClaimCategory';
import useImpactClaims from 'components/ImpactClaims/hooks/useImpactClaims';

interface Props {
  initialImpactClaim: ImpactCatalogItemMultiLang;
}

const BasicFields: React.FC<Props> = ({ initialImpactClaim }: Props) => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<ImpactCatalogItemMultiLang>();

  const { impactCatalogCategories, sdgs } = useImpactClaims();

  const iconUrl: string | undefined = watch('iconUrl');

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        <FormControl fullWidth error={!!errors['categoryId']}>
          <TextField
            select
            {...register('categoryId', {
              required: true,
            })}
            fullWidth
            defaultValue={initialImpactClaim.categoryId}
            label="Category"
            variant="outlined"
          >
            {impactCatalogCategories.map(({ id, type }) => (
              <MenuItem key={type} value={id}>
                <ClaimCategory categoryType={type} />
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>

      <Grid item lg={6} xs={12}>
        <FormControl fullWidth error={!!errors['sustainableDevGoalsIds']}>
          <TextField
            select
            {...register('sustainableDevGoalsIds')}
            fullWidth
            defaultValue={initialImpactClaim.sustainableDevGoalsIds}
            label="SDG's"
            SelectProps={{
              multiple: true,
            }}
            variant="outlined"
          >
            {sdgs.map(({ id, title }) => (
              <MenuItem key={title} value={id}>
                {title}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>

      <DottedLine />

      <Grid item lg={6} xs={12}>
        <ListField field="links" />
      </Grid>

      <Grid item lg={6} xs={12}>
        <ListField field="thirdParties" />
      </Grid>

      <DottedLine />

      <Grid item lg={6} xs={12}>
        <ImageUploadField
          buttonText="Add Impact Claim Icon"
          imageUrl={iconUrl}
          label="Impact claim icon"
          targetFolder="defaults/impact-claim-icons/"
          onAddImage={(url: string) => {
            setValue('iconUrl', url);
          }}
          onRemoveImage={() => {
            setValue('iconUrl', undefined);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BasicFields;
