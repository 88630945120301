import { toast } from 'react-toastify';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  ServerError
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { onError } from '@apollo/link-error';
import { CachePersistor } from 'apollo-cache-persist';
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types';
import { getAuth } from 'firebase/auth';
import resolvers from 'graphql/resolvers';
import typeDefs from 'graphql/typeDefs';
import typePolicies from 'graphql/typePolicies';
import omitDeep from 'omit-deep-lodash';

const cache = new InMemoryCache({
  typePolicies
});

export const cachePersistor = new CachePersistor({
  cache,
  debounce: 1000,
  storage: window.localStorage as PersistentStorage<PersistedData<string>>
});

interface Props {
  uri: string;
}

const initializeApolloClient = async ({ uri }: Props) => {
  await cachePersistor.restore();

  const httpLink = createHttpLink({ uri });

  const authLink = setContext(async (_, { headers }) => {
    let customHeaders = {};

    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();

      customHeaders = {
        authorization: token ? `Bearer ${token}` : ''
      };
    } catch (e) {
      console.error(e);
    }

    return {
      headers: {
        ...headers,
        ...customHeaders
      }
    };
  });

  // not equal types in schema to avoid breaking mutations
  const omitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      // eslint-disable-next-line no-param-reassign
      operation.variables = omitDeep(operation.variables, '__typename');
    }

    return forward(operation);
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    return forward(operation);
  });

  const errorLink = onError(error => {
    const { graphQLErrors, networkError } = error;
    if ((networkError as ServerError)?.statusCode === 401) {
      window.location.href = '/login';
      toast('Are you sure you are an admin user?');
    } else if (networkError) toast(`[Network error]: ${networkError}`);
    if (graphQLErrors) toast(`[graphQLErrors]: ${graphQLErrors}`);
  });

  const link = from([
    errorLink,
    middlewareLink,
    omitTypenameLink,
    authLink.concat(httpLink)
  ]);

  const client = new ApolloClient({
    cache,
    link,
    resolvers,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first'
      }
    }
  });

  return client;
};

export default initializeApolloClient;
