import { CERTIFICATION_CATALOG_ITEM_FRAGMENT } from 'graphql/fragments';
import gql from 'graphql-tag';

export const GET_CERTIFICATION_CATALOG_ITEMS = gql`
  query GetCertificateCatalogItems($status: [CertificateCatalogItemStatus]) {
    certificateCatalogItems(status: $status) {
      edges {
        node {
          ...certificateCatalogItemValues
        }
      }
    }
  }
  ${CERTIFICATION_CATALOG_ITEM_FRAGMENT}
`;

export const GET_CERTIFICATION_CATALOG_ITEM = gql`
  query GetCertificateCatalogItem($id: UUID!) {
    certificateCatalogItem(id: $id) {
      ...certificateCatalogItemValues
    }
  }
`;
