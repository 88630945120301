import { gql } from '@apollo/client';

export const COMPANY_ANALYTICS_SUMMARY_FIELDS_FRAGMENT = gql`
  fragment productAnalyticsFields on ProductsAnalytics {
    mostViewedProduct
    topCity {
      city
      value
      weight
    }
    views {
      current
      previous
      percentageChange
    }
    qrViews {
      current
      previous
      percentageChange
    }
    uniqueViews {
      current
      previous
      percentageChange
    }
    directViews {
      current
      previous
      percentageChange
    }
    uniqueQrViews {
      current
      previous
      percentageChange
    }
    uniqueDirectViews {
      current
      previous
      percentageChange
    }
    averageTimeSpent {
      current
      previous
      percentageChange
    }
  }
`;

export const PRODUCT_ANALYTICS_SUMMARY_FIELDS_FRAGMENT = gql`
  fragment analyticsSummaryFields on AnalyticsSummary {
    totalViewsSincePublish
    totalQrViewsSincePublish
    totalUniqueViewsSincePublish
    averageTimeSpent {
      current
      previous
      percentageChange
    }
    views {
      current
      previous
      percentageChange
    }
    qrViews {
      current
      previous
      percentageChange
    }
    uniqueViews {
      current
      previous
      percentageChange
    }
    directViews {
      current
      previous
      percentageChange
    }
    uniqueQrViews {
      current
      previous
      percentageChange
    }
    uniqueDirectViews {
      current
      previous
      percentageChange
    }
  }
`;

export const PAGE_ACTIVITY_FRAGMENT = gql`
  fragment pageActivityFields on PageActivity {
    total {
      value
      date
    }
    totalUnique {
      value
      date
    }
    qr {
      value
      date
    }
    qrUnique {
      value
      date
    }
    direct {
      value
      date
    }
    directUnique {
      value
      date
    }
    timeInterval
  }
`;

export const DEVICE_INFO_FRAGMENT = gql`
  fragment deviceInfoFields on DeviceInfo {
    ios
    android
    desktop
    others
  }
`;

export const GEO_ANALYTICS_FRAGMENT = gql`
  fragment geoAnalyticsFields on GeoAnalytics {
    lat
    lng
    weight
  }
`;
