import React from 'react';
import styled from 'styled-components';
import { ImpactClaim } from 'types/types.types';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  border-radius: 6px;
  padding: 16px;
  color: white;
  font-size: 14px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  grid-gap: 10px;
`;

interface Props {
  impactClaim?: ImpactClaim;
}

const ClaimDefinition: React.FC<Props> = ({
  impactClaim,
}: Props): JSX.Element => {
  return (
    <Container>
      <TitleRow>
        <span>{impactClaim?.impactCatalogItem?.title}</span>
      </TitleRow>

      <div>{impactClaim?.impactCatalogItem?.definition}</div>
    </Container>
  );
};

export default ClaimDefinition;
