import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_COMPANY_PLAN } from 'graphql/mutations/companies';
import { UpdateCompanyPlanPayload } from 'types/mutations.types';

export const useUpdateCompany = (options: { onSuccess: () => void }) => {
  const [updateCompanyFeatures, { data, error, loading }] =
    useMutation<UpdateCompanyPlanPayload>(UPDATE_COMPANY_PLAN, {
      onCompleted: () => {
        options.onSuccess();
        toast.success('Company edited succesfully!');
      },
      onError: () => {
        toast.error('Something went wrong while updating the company details.');
      },
    });

  return {
    updateCompanyFeatures,
    data,
    loading,
    error,
  };
};
