import React, { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Firebase, FirebaseProvider } from 'contexts';
import ApolloProvider from 'graphql/ApolloProvider';
import { DefaultTheme } from 'styled-components';
import theme, { CompanyTheme } from 'styles/theme';
import Routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';

const firebase = new Firebase();

const graphqlEndpoint = process.env.REACT_APP_GQL_ENDPOINT;

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <StrictMode>
    <ThemeProvider<DefaultTheme & CompanyTheme> theme={theme}>
      <FirebaseProvider firebase={firebase}>
        <ApolloProvider graphqlEndpoint={graphqlEndpoint}>
          <Fragment>
            <ToastContainer
              closeOnClick
              draggable
              pauseOnFocusLoss
              pauseOnHover
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              position="bottom-left"
              rtl={false}
            />
            <CssBaseline />

            <Routes />
          </Fragment>
        </ApolloProvider>
      </FirebaseProvider>
    </ThemeProvider>
  </StrictMode>
);
