import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CustomerPlan, PlanType } from 'types/companies.types';
import { formatDate, formatDateInBackendFormat } from 'utils/dates';
import { displayNullableValue } from 'utils/general';
import { useUpdateCompany } from 'components/Companies/hooks/useUpdateCompany';
import BooleanValueIndicator from 'components/shared/BooleanValueIndicator/BooleanValueIndicator';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { Label } from 'components/shared/LabelWithValue/styles';
import { StyledContainer, StyledGrid } from '../styles';

interface Props {
  companyId: string;
  planDetails: CustomerPlan;
}

const CompanyPlan: React.FC<Props> = ({ companyId, planDetails }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [expirationDate, setExpirationDate] = useState<string | null>(null);
  const [planType, setPlanType] = useState<PlanType>(planDetails.planType);

  const { updateCompanyFeatures } = useUpdateCompany({
    onSuccess: () => {
      setIsEditing(false);
    },
  });

  useEffect(() => {
    if (planDetails.expirationDate) {
      setExpirationDate(formatDateInBackendFormat(planDetails.expirationDate));
    }
  }, [planDetails.expirationDate]);

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between">
        <Typography gutterBottom fontWeight={700} variant="subtitle1">
          Plan Details
        </Typography>
        <div>
          {isEditing && (
            <Button size="small" onClick={() => setIsEditing(!isEditing)}>
              Cancel
            </Button>
          )}
          <Tooltip
            title={
              isEditing
                ? 'You must select a valid expiration date or remove it before saving'
                : ''
            }
          >
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                if (isEditing) {
                  updateCompanyFeatures({
                    variables: {
                      id: companyId,
                      input: {
                        // Fixme maybe: the additional value is not a great way to achieve this but graphql cannot distinguish between setting the field to null and not sending a value at all which is a big difference
                        updateExpirationDate: true,
                        expirationDate,
                        planType,
                      },
                    },
                  });
                } else {
                  setIsEditing(!isEditing);
                }
              }}
            >
              {isEditing ? 'Save' : 'Edit Plan'}
            </Button>
          </Tooltip>
        </div>
      </Box>
      <StyledGrid container spacing={3}>
        {isEditing ? (
          <Grid container item spacing={1}>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Label>Expiration Date</Label>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params: TextFieldProps) => (
                    <TextField
                      size="small"
                      {...params}
                      helperText={
                        isEditing
                          ? 'Expiration date must be selected or removed'
                          : 'dd/mm/yyyy'
                      }
                    />
                  )}
                  value={expirationDate}
                  onChange={(newValue: string | null) => {
                    if (newValue) {
                      setExpirationDate(formatDateInBackendFormat(newValue));
                    } else {
                      setExpirationDate(null);
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Label>Plan Type</Label>
              <TextField
                select
                defaultValue={planType}
                helperText="The plan type is only used to distinguish our paying customers from internal/trial accounts"
                size="small"
                value={planType}
                onChange={e => setPlanType(e.target.value as PlanType)}
              >
                {['INTERNAL', 'PAYING', 'TRIAL'].map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : (
          <React.Fragment>
            <LabelWithValue label="Expiration Date">
              {planDetails.expirationDate
                ? formatDate(planDetails.expirationDate)
                : displayNullableValue(planDetails?.expirationDate)}
            </LabelWithValue>
            <LabelWithValue label="Has Expired">
              <BooleanValueIndicator value={planDetails?.expired} />
            </LabelWithValue>
            <LabelWithValue label="Upgrade Requested">
              <BooleanValueIndicator value={planDetails?.upgradeRequested} />
            </LabelWithValue>
            <LabelWithValue label="Plan Type">
              {displayNullableValue(planDetails?.planType)}
            </LabelWithValue>
            <LabelWithValue label="Plan">
              {displayNullableValue(planDetails?.name)}
            </LabelWithValue>
            <LabelWithValue label="Product Limit">
              {displayNullableValue(planDetails?.productLimit)}
            </LabelWithValue>
            <LabelWithValue label="User Limit">
              {displayNullableValue(planDetails?.userLimit)}
            </LabelWithValue>
          </React.Fragment>
        )}
      </StyledGrid>
    </StyledContainer>
  );
};

export default CompanyPlan;
