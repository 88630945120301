import { useContext } from 'react';
import { FirebaseContext } from 'contexts';

const useFirebase = () => {
  const firebaseContext = useContext(FirebaseContext);

  return firebaseContext;
};

export default useFirebase;
