import React, { useMemo, useRef } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { EMPTY_CERTIFICATION } from 'constants/Certifications';
import {
  CertificateCatalogItemStatus,
  CertificationCatalogItemMultiLang,
} from 'types/types.types';
import { updateInitialCertificationWithMultiLangFields } from 'utils/certifications';
import BasicFields from 'components/Certifications/ActiveCertificationCatalogItem/BasicFields/BasicFields';
import {
  ButtonContainer,
  FieldsContainer,
  StyledForm,
} from 'components/ImpactClaims/ActiveImpactClaim/ImpactClaimForm/styles';
import CertificationStatusBlock from '../CertificationStatusBlock/CertificationStatusBlock';
import MultiLangFields from '../MultiLangFields/MultiLangFields';

interface Props {
  creatingCertification: boolean;
  handleUpdateCatalogItemStatus: (
    status: CertificateCatalogItemStatus
  ) => Promise<void>;
  initialCertification?: CertificationCatalogItemMultiLang;
  onSubmit: SubmitHandler<CertificationCatalogItemMultiLang>;
}

const CertificationForm: React.FC<Props> = ({
  creatingCertification,
  handleUpdateCatalogItemStatus,
  initialCertification,
  onSubmit,
}: Props) => {
  const defaultValues = useMemo(
    () =>
      !creatingCertification && initialCertification
        ? updateInitialCertificationWithMultiLangFields(initialCertification)
        : EMPTY_CERTIFICATION,
    [creatingCertification, initialCertification]
  );
  const methods = useForm<CertificationCatalogItemMultiLang>({
    defaultValues,
  });

  const formRef = useRef<HTMLFormElement>(null);

  const submitAndApprove = () => {
    if (formRef.current) {
      methods.setValue('status', 'APPROVED');
      // Dispatch a submit event so the form receives all new values from the useForm hook.
      // Recommended way of remote sumission of the form according to the docs: https://codesandbox.io/s/remote-handlesubmit-ipbqb?file=/src/App.js
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
        {!creatingCertification && initialCertification && (
          <CertificationStatusBlock
            activeCertification={initialCertification}
            handleUpdateCatalogItemStatus={handleUpdateCatalogItemStatus}
            isFormDirty={methods.formState.isDirty}
            triggerValidation={methods.trigger}
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FieldsContainer>
              <BasicFields />
            </FieldsContainer>
          </Grid>

          <Grid item xs={12}>
            <FieldsContainer>
              <MultiLangFields initialCertification={defaultValues} />
            </FieldsContainer>
          </Grid>
        </Grid>

        <ButtonContainer>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => methods.reset()}
          >
            Discard
          </Button>
          {!creatingCertification &&
            initialCertification?.status !== 'APPROVED' && (
              <Button
                color="secondary"
                size="large"
                variant="outlined"
                onClick={submitAndApprove}
              >
                Save & Approve
              </Button>
            )}
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </ButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};

export default CertificationForm;
