import React, { Fragment, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover
} from '@mui/material';
import styled from 'styled-components';
import { StyledIcon } from 'styled-icons/types';

const StyledListItemButton = styled(ListItemButton)`
  &.Mui-selected {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const PopUpContainer = styled.div`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const PopUpButton = styled(ListItemButton)`
  background: ${({ theme }) => theme.palette.primary.main};
`;

interface MenuItem {
  icon?: StyledIcon;
  overViewPage: { text: string; url: string };
  text: string;
  url: string;
}

interface Props {
  icon?: StyledIcon;
  subMenu: MenuItem[];
  text: string;
}

const MenuItem: React.FC<Props> = ({ icon: Icon, subMenu, text }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const {pathname} = location;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const menuActive = useMemo(() => {
    const subItemMatch = subMenu.find(item =>
      matchPath(item.overViewPage.url, pathname)
    );
    if (subItemMatch) return true;

    return !!subMenu.find(item => matchPath(item.url, pathname));
  }, [pathname]);

  return (
    <Fragment key={text}>
      <StyledListItemButton
        color="primary"
        selected={menuActive}
        onClick={e =>
          subMenu.length === 1 ? navigate(subMenu[0].url) : handlePopoverOpen(e)
        }
      >
        <ListItemIcon sx={{ minWidth: 30 }}>
          {Icon ? <Icon color="#fff" size={16} /> : null}
        </ListItemIcon>
        <ListItemText primary={text} />
      </StyledListItemButton>
      <Popover
        disableRestoreFocus
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        id={`popover-${text}`}
        open={!!anchorEl}
        onClose={handlePopoverClose}
      >
        <PopUpContainer>
          {subMenu.map(item => (
            <PopUpButton
              color="primary"
              key={item.text}
              onClick={() => {
                navigate(item.url);
                handlePopoverClose();
              }}
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                {item.icon ? <item.icon color="#fff" size={16} /> : null}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </PopUpButton>
          ))}
        </PopUpContainer>
      </Popover>
    </Fragment>
  );
};
export default MenuItem;
