import { useQuery } from '@apollo/client';
import { GET_COMPANY_ITEMS } from 'graphql/queries/companies';
import { CompanyFetchResult } from 'types/companies.types';

export const useCompanies = () => {
  const { data, error, loading } = useQuery<CompanyFetchResult>(
    GET_COMPANY_ITEMS,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    companies: data?.companies.edges.map(({ node }) => node) || [],
    error,
    loading,
  };
};
