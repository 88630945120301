import React, { FC, useState } from 'react';
import { Avatar, Box, Grid, Link, Tooltip, Typography } from '@mui/material';
import { Product } from 'types/companies.types';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import { getProductStatusColor } from 'utils/statusUtils';
import { CompanyAttributeRow } from 'components/Companies/ActiveCompanyPage/styles';
import CountryFlag from 'components/shared/CountryFlag/CountryFlag';

interface ProductRowProps {
  product: Product;
}

const AVATAR_SIZE = 40;

const ProductRow: FC<ProductRowProps> = ({ product }) => {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <CompanyAttributeRow>
      <Grid container alignItems={'center'}>
        <Grid item lg={6} sx={{ display: 'flex', alignItems: 'center' }} xs={6}>
          <Avatar
            src={product.imageUrl}
            sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
            variant="square"
          />
          <Box display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" flexDirection="row">
              <Typography ml={2}>{product.title}</Typography>
              <Tooltip
                leaveDelay={400}
                open={copied}
                title="Copied product ID to clipboard"
                onClose={() => setCopied(false)}
              >
                <Box
                  ml={1}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setCopied(true);
                    copyTextToClipboard(product.id);
                  }}
                >
                  <Typography
                    color="grey"
                    fontSize={10}
                    style={{
                      whiteSpace: 'nowrap',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {product.id}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
            <Typography
              color={getProductStatusColor(product.status)}
              ml={2}
              variant="caption"
            >
              {product.status}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={2} xs={2}>
          <Grid container spacing={1}>
            {product.languageConfig?.enabled.map(language => {
              const isPublished =
                product.publishedLanguages.includes(language) ?? false;
              return (
                <CountryFlag
                  isPublished={isPublished}
                  key={`flag-${language}`}
                  language={language}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}
          xs={4}
        >
          <Link
            color="secondary"
            href={`${product.publicUrl}?preview=1`}
            mr={2}
            target="blank"
            variant="body2"
          >
            To Product
          </Link>
        </Grid>
      </Grid>
    </CompanyAttributeRow>
  );
};

export default ProductRow;
