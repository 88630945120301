import { AnalyticsActivityItem } from 'types/analytics.types';
import { formatDate } from './dates';

interface LabelValueCollection {
  labels: string[];
  values: number[];
}

export const splitAnalyticsLabelAndValues = (
  items?: AnalyticsActivityItem[]
): {
  labels: string[];
  values: number[];
} => {
  if (!items) {
    return {
      labels: [],
      values: [],
    };
  }

  const initialCollection: LabelValueCollection = {
    labels: [],
    values: [],
  };

  const LabelValueCollection: LabelValueCollection = items.reduce(
    (prev: LabelValueCollection, current: AnalyticsActivityItem) => {
      return {
        labels: [...prev.labels, formatDate(current.date)],
        values: [...prev.values, current.value],
      };
    },
    initialCollection
  );

  return LabelValueCollection;
};
