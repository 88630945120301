import { IRawMaterialLogAction } from 'types/rawMaterials.types';

export const getAvailableStatusUpdates = (
  status?: IRawMaterialLogAction
): Array<IRawMaterialLogAction> => {
  switch (status) {
    case 'REQUESTED':
      return [
        'APPROVED' as IRawMaterialLogAction,
        'DENIED' as IRawMaterialLogAction,
      ];
    case 'APPROVED':
      return ['DENIED' as IRawMaterialLogAction];
    case 'DENIED':
      return ['APPROVED' as IRawMaterialLogAction];
    default:
      return [];
  }
};
