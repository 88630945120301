import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Alert, AlertTitle, Box, capitalize, styled } from '@mui/material';
import { ChartOptions } from 'chart.js';
import { DeviceAnalytics } from 'types/analytics.types';

interface ChartDataItem {
  part: number;
  percentage: number;
  total: number;
}

export const ChartWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface Props {
  deviceInfo: DeviceAnalytics;
  maxWidth?: number;
  totalVisits: number;
}

const chartOptions: ChartOptions<'doughnut'> = {
  parsing: {
    key: 'percentage',
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          const raw: ChartDataItem = context.raw as ChartDataItem;

          return `${context.label}: ${raw.percentage}% (${raw.part} ${
            raw.part > 1 ? 'visits' : 'visit'
          })`;
        },
      },
    },
  },
};

const DeviceChart: React.FC<Props> = ({
  deviceInfo,
  maxWidth,
  totalVisits,
}) => {
  const { android, desktop, ios, others } = deviceInfo;
  const chartData = [ios, android, desktop, others].map(value => {
    // Convert to percentage instead of fraction, and round by 2 decimals
    const percentage = Math.round(value * 10000) / 100;
    return {
      percentage,
      total: totalVisits,
      part: Math.round(totalVisits * value),
    };
  });
  const hasValues: boolean = chartData.some(item => item.percentage > 0);
  const width: string | number = maxWidth !== undefined ? maxWidth : 'unset';
  const labels = Object.keys(deviceInfo)
    .map(title => (title.startsWith('__') ? undefined : capitalize(title)))
    .filter(item => item !== undefined);

  if (!hasValues) {
    return (
      <Alert severity="info">
        <AlertTitle>No Data</AlertTitle>
        No device related data has been observed.
      </Alert>
    );
  }

  return (
    <ChartWrapper>
      <Box maxWidth={width}>
        <Doughnut
          data={{
            labels,
            datasets: [
              {
                data: chartData,
                backgroundColor: [
                  'rgb(255, 183, 1)',
                  'rgb(95, 170, 60)',
                  'rgba(233, 97, 56, 0.959)',
                  'rgba(91, 134, 204, 0.863)',
                ],
              },
            ],
          }}
          options={chartOptions}
        />
      </Box>
    </ChartWrapper>
  );
};

export default DeviceChart;
