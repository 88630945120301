import React from 'react';
import { Typography } from '@mui/material';
import { ExtendedCompany } from 'types/companies.types';
import { displayNullableValue } from 'utils/general';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { StyledContainer, StyledGrid } from '../styles';

interface Props {
  company: ExtendedCompany;
}

const CompanyAddress: React.FC<Props> = ({ company }) => {
  return (
    <StyledContainer>
      <Typography gutterBottom fontWeight={700} variant="subtitle1">
        Address
      </Typography>
      <StyledGrid container spacing={3}>
        <LabelWithValue label="Country">
          {displayNullableValue(company.address?.country)}
        </LabelWithValue>
        <LabelWithValue label="City">
          {displayNullableValue(company.address?.city)}
        </LabelWithValue>
        <LabelWithValue label="Street">
          {displayNullableValue(company.address?.street)}
        </LabelWithValue>
        <LabelWithValue label="Street Number">
          {displayNullableValue(company.address?.streetNumber)}
        </LabelWithValue>
        <LabelWithValue label="Zip Code">
          {displayNullableValue(company.address?.zipCode)}
        </LabelWithValue>
      </StyledGrid>
    </StyledContainer>
  );
};

export default CompanyAddress;
