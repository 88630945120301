import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@mui/material';
import languages from 'constants/languages';
import { CertificationCatalogItemMultiLang } from 'types/types.types';
import MultiLangTextField from 'components/FormParts/MultiLangTextField';
import { DottedLine } from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/styles';

interface Props {
  initialCertification: CertificationCatalogItemMultiLang;
}

const MultiLangFields: React.FC<Props> = ({ initialCertification }: Props) => {
  const { formState } = useFormContext<CertificationCatalogItemMultiLang>();
  const { errors } = formState;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {languages.map(({ full: lang }) => (
            <Grid item key={lang} md={(12 - 1) / languages.length} xs={0}>
              <b>{lang}</b>
            </Grid>
          ))}
        </Grid>

        <Grid container direction="column" mt={2} spacing={1}>
          <Grid item>
            <MultiLangTextField
              error={!!errors['title']}
              field="title"
              languageArray={initialCertification.title}
            />
          </Grid>
          <DottedLine />
          <Grid item>
            <MultiLangTextField
              multiline
              error={!!errors['description']}
              field="description"
              languageArray={initialCertification.description}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MultiLangFields;
