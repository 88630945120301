import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_IMPACT_CATALOG_ITEM,
  DELETE_IMPACT_CATALOG_ITEM,
  UPDATE_IMPACT_CATALOG_ITEM,
} from 'graphql/mutations/impactClaims';
import { GET_IMPACT_CATALOG_ITEMS, GET_SDGS } from 'graphql/queries';
import { GET_IMPACT_CATALOG_CATEGORIES } from 'graphql/queries/impactClaims';
import { ChangeClaimSubmissionStatusPayload } from 'types/mutations.types';
import {
  ImpactCatalogCategoryResult,
  ImpactCatalogItemMultiLang,
  ImpactCatalogResult,
  SDGsResult,
} from 'types/types.types';

interface Props {
  activeClaimId?: string;
}

const useImpactClaims = ({ activeClaimId }: Props = {}) => {
  const navigate = useNavigate();

  const {
    data: { impactCatalogItems: { edges: impactClaims = [] } = {} } = {},
    error,
    loading,
    refetch,
  } = useQuery<ImpactCatalogResult>(GET_IMPACT_CATALOG_ITEMS, {
    fetchPolicy: activeClaimId ? 'cache-first' : 'cache-and-network',
  });

  const {
    data: { sdgs: { edges: sdgs = [] } = {} } = {},
    error: errorSDGs,
    loading: loadingSDGs,
  } = useQuery<SDGsResult>(GET_SDGS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      impactCatalogCategories: { edges: impactCatalogCategories = [] } = {},
    } = {},
    error: errorCategories,
    loading: loadingCategories,
  } = useQuery<ImpactCatalogCategoryResult>(GET_IMPACT_CATALOG_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateImpactCatalog] = useMutation<ChangeClaimSubmissionStatusPayload>(
    UPDATE_IMPACT_CATALOG_ITEM,
    {
      onCompleted: () => {
        toast.success('Success! Your details were updated.');
        navigate(`/impact-claims`);
      },
      onError: () => {
        toast.error('Something went wrong updating your details.');
      },
    }
  );

  const [createImpactCatalog] = useMutation<ChangeClaimSubmissionStatusPayload>(
    CREATE_IMPACT_CATALOG_ITEM,
    {
      onCompleted: () => {
        toast.success('Awesome, you succesfully created an impact claim');
        navigate(`/impact-claims`);
      },
      onError: () => {
        toast.error('Something went wrong :(');
      },
    }
  );

  const [deleteImpactCatalogs] =
    useMutation<ChangeClaimSubmissionStatusPayload>(
      DELETE_IMPACT_CATALOG_ITEM,
      {
        onCompleted: () => {
          toast.success('Yes, you have deleted the impact claim');
          navigate(`/impact-claims`);
        },
        onError: () => {
          toast.error('Something went wrong :(');
        },
      }
    );

  const handleImpactClaimClick = (impactClaimId: string) => {
    navigate(`/impact-claims/${impactClaimId}`);
  };

  const activeImpactClaim = useMemo(() => {
    return impactClaims.find(({ node }) => node.id === activeClaimId)?.node;
  }, [activeClaimId, impactClaims]);

  const handleSaveUpdate = (values: ImpactCatalogItemMultiLang) => {
    updateImpactCatalog({
      variables: {
        id: activeClaimId,
        input: {
          category: values.categoryId,
          sustainableDevGoals: values.sustainableDevGoalsIds,
          links: values.links,
          thirdParties: values.thirdParties,
          title: values.title,
          definition: values.definition,
          acceptanceCriteria: values.acceptanceCriteria,
          docRequirements: values.docRequirements,
          iconUrl: values.iconUrl || null,
        },
      },
    });
  };

  const handleSaveCreate = (values: ImpactCatalogItemMultiLang) => {
    createImpactCatalog({
      variables: {
        input: {
          category: values.categoryId,
          sustainableDevGoals: values.sustainableDevGoalsIds,
          links: values.links,
          thirdParties: values.thirdParties,
          title: values.title,
          types: values.types,
          definition: values.definition,
          acceptanceCriteria: values.acceptanceCriteria,
          docRequirements: values.docRequirements,
          iconUrl: values.iconUrl,
        },
      },
    });
  };

  const handleOpenNewForm = () => {
    navigate(`/impact-claims/new`);
  };

  const handleDeleteImpactClaim = (id: string) => {
    // Confirmation is already asked in component
    deleteImpactCatalogs({ variables: { ids: [id] } });
  };

  return {
    impactClaims: impactClaims.map(({ node }) => node),
    error: error || errorCategories || errorSDGs,
    loading: loading || loadingCategories || loadingSDGs,
    handleImpactClaimClick,
    activeImpactClaim: activeImpactClaim,
    impactCatalogCategories: impactCatalogCategories.map(({ node }) => node),
    refetch,
    handleSaveCreate,
    handleSaveUpdate,
    handleOpenNewForm,
    handleDeleteImpactClaim,
    sdgs: sdgs.map(({ node }) => node),
  };
};

export default useImpactClaims;
