import { AppBar } from '@mui/material';
import styled from 'styled-components';

export const StyledAppBar = styled(AppBar)`
  background: #fff;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const PageInner = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
`;
