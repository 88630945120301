import React, { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Avatar,
  Box,
  MenuItem,
  Select,
  TableCell,
  Typography
} from '@mui/material';
import { PlanType } from 'constants/types';
import get from 'lodash/get';
import styled from 'styled-components';
import { Company } from 'types/companies.types';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault
} from 'use-query-params';
import { formatDateTime } from 'utils/dates';
import Loader from 'components/Loader/Loader';
import Table, { HeadCell } from 'components/Table/Table';
import { useCompanies } from '../hooks/useCompanies';
import { SearchField } from './styles';

const StyledSelect = styled(Select)`
  margin-left: ${({ theme }) => theme.spacing(1)};

  .MuiSelect-select {
    color: black;
    padding: ${({ theme }) => theme.spacing(1, 1.5)};
  }
`;

const headCells: HeadCell<Company>[] = [
  {
    id: 'logo',
    label: ''
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'website',
    label: 'Website'
  },
  {
    id: 'planDetails.expirationDate',
    label: 'Expiration Date'
  },
  {
    id: 'invitedByCompany.name',
    label: 'Invited by'
  },
  {
    id: 'createdTimestamp',
    label: 'Created On'
  }
];

const ICON_SIZE = 35;

type CompanyStatus = 'active' | 'expired';

const CompaniesPage: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    planType: withDefault(StringParam, PlanType.PAYING),
    status: withDefault(StringParam, 'active'),
    pageSize: withDefault(NumberParam, 20),
    page: withDefault(NumberParam, 0),
    customerType: withDefault(StringParam, 'clients')
  });
  const { customerType, page, pageSize, planType, search, status } = query;

  const { companies, error, loading } = useCompanies();
  const navigate = useNavigate();

  const filteredCompanies = useMemo(
    () =>
      companies?.filter(
        company =>
          company.name.toLowerCase().includes(search.toLowerCase()) &&
          company.planDetails?.planType === planType &&
          ((customerType === 'clients' && company.customerType === 'DEFAULT') ||
            (customerType === 'partners' &&
              company.customerType === 'PARTNER')) &&
          (status === 'active'
            ? !company.planDetails?.expired
            : company.planDetails?.expired)
      ),
    [planType, search, companies, status, customerType]
  );

  const handleNavigateToCompany = useCallback(
    (companyId: string) => {
      navigate(`/companies/${companyId}`);
    },
    [navigate]
  );

  const filteredHeadCells = useMemo(
    () =>
      customerType === 'clients'
        ? headCells.filter(({ id }) => id !== 'invitedByCompany.name')
        : headCells,
    [headCells, customerType]
  );

  if (loading && companies.length === 0) {
    return <Loader />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <SearchField
        id="outlined-basic"
        placeholder="Search..."
        size="small"
        value={search}
        variant="outlined"
        onChange={event => {
          setQuery({ search: event.target.value, page: 0 });
        }}
      />
      <StyledSelect
        value={planType}
        onChange={event => {
          setQuery({ planType: event.target.value as PlanType, page: 0 });
        }}
      >
        {Object.keys(PlanType).map(planType => (
          <MenuItem key={planType} value={planType}>
            {planType}
          </MenuItem>
        ))}
      </StyledSelect>
      <StyledSelect
        value={status}
        onChange={event => {
          setQuery({ status: event.target.value as CompanyStatus, page: 0 });
        }}
      >
        <MenuItem value="active">ACTIVE</MenuItem>
        <MenuItem value="expired">EXPIRED</MenuItem>
      </StyledSelect>
      <StyledSelect
        value={customerType}
        onChange={event => {
          setQuery({ customerType: event.target.value as string, page: 0 });
        }}
      >
        <MenuItem value="clients">Clients</MenuItem>
        <MenuItem value="partners">Partners</MenuItem>
      </StyledSelect>

      <Box mb={2}>
        <Typography color="GrayText" fontWeight={500} variant="body2">
          {companies.length} total registered companies,{' '}
          {filteredCompanies.length} companies available with applied filters
        </Typography>
      </Box>

      <Table<Company>
        defaultRowsPerPage={20}
        defaultSorting="title"
        headCells={filteredHeadCells}
        page={page}
        pageSize={pageSize}
        rows={filteredCompanies || []}
        onPageChange={page => setQuery({ page })}
        onPageSizeChange={pageSize => setQuery({ pageSize })}
        onRowClick={handleNavigateToCompany}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              if (id === 'logo') {
                return (
                  <TableCell key={id} size="small" sx={{ width: '60px' }}>
                    <Avatar
                      src={row.logo?.url}
                      sx={{ width: ICON_SIZE, height: ICON_SIZE }}
                    />
                  </TableCell>
                );
              }

              if (id === 'createdTimestamp') {
                return (
                  <TableCell key={id} size="small">
                    <div>{formatDateTime(get(row, id))}</div>
                  </TableCell>
                );
              }

              if (id === 'name') {
                const isExpired = !!get(row, 'planDetails.expired');
                return (
                  <TableCell key={id} size="small">
                    <div>{get(row, id)}</div>
                    <Typography
                      color={isExpired ? 'orange' : 'green'}
                      variant="caption"
                    >
                      {isExpired ? 'Expired' : 'Active'}
                    </Typography>
                  </TableCell>
                );
              }

              if (
                id === 'invitedByCompany.name' &&
                customerType === 'partners'
              ) {
                return (
                  <TableCell key={id} size="small">
                    <div>{get(row, id)}</div>
                  </TableCell>
                );
              }

              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </div>
  );
};

export default CompaniesPage;
