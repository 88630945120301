import { ApolloClient, InMemoryCache } from '@apollo/client';
import { Firebase } from 'contexts';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { cachePersistor } from 'graphql/client';

interface LoginResolverProps {
  email: string;
  firebase: Firebase;
  password: string;
}

const authResolvers = {
  Mutation: {
    login: async (_: null, { email, password }: LoginResolverProps) => {
      // const firebaseSource = firebase.getFirebase();
      // firebaseSource.

      try {
        const auth = getAuth();
        await signInWithEmailAndPassword(auth, email, password);
        const token = await auth.currentUser?.getIdToken();
        if (!token) {
          throw new Error('Login went wrong');
        }
      } catch (error: any) {
        throw new Error(error.code || error.message);
      }
    },
    logout: async (
      _: null,
      props: null,
      { client }: { client: ApolloClient<InMemoryCache> }
    ) => {
      const auth = getAuth();
      auth.signOut();

      cachePersistor.purge();
      await client.clearStore();
    }
  }
};

export default authResolvers;
