import { gql } from '@apollo/client';
import { ADMIN_COMPANY_FRAGMENT } from 'graphql/fragments/company';
import { PRODUCT_FRAGMENT } from 'graphql/fragments/product';

export const GET_COMPANY_ITEMS = gql`
  query GetCompanies {
    companies {
      edges {
        node {
          ...adminCompanyValues
        }
      }
    }
  }
  ${ADMIN_COMPANY_FRAGMENT}
`;

export const GET_SINGLE_COMPANY_AND_PRODUCTS = gql`
  query GetCompany($id: UUID!) {
    company(id: $id) {
      ...adminCompanyValues
      products {
        kpis {
          total
          drafts
          published
        }
        edges {
          node {
            ...companyProductValues
          }
        }
      }
      users {
        kpis {
          total
          registered
          notRegistered
        }
        edges {
          node {
            id
            email
            latestActivityTimestamp
            firstName
            lastName
          }
        }
      }
      impactClaims {
        kpis {
          total
          requested
          approved
        }
        edges {
          node {
            id
            status
            createdTimestamp
            impactCatalogItem {
              title
              category {
                title
                type
              }
            }
          }
        }
      }
    }
  }
  ${ADMIN_COMPANY_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`;
