import React, { FC, ReactNode } from 'react';
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight';
import { ReactComponent as CertificateIcon } from 'images/claims/certificate.svg';
import { ReactComponent as CertificateMultiIcon } from 'images/claims/certificates.svg';
import { useTheme } from 'styled-components';
import { ClaimAttachmentType } from 'types/types.types';
import { formatDateTime } from 'utils/dates';
import {
  Container,
  IconContainer,
  SubTitle,
  Title,
  TitleContainer
} from './styles';

interface Props {
  active?: boolean;
  children: ReactNode;
  document?: ClaimAttachmentType;
  multi?: boolean;
  onDocumentClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const DocumentContainer: FC<Props> = ({
  active = false,
  children,
  document,
  multi = false,
  onDocumentClick
}: Props) => {
  const theme = useTheme();

  return (
    <Container
      active={active}
      href={document?.url}
      target={document && '_blank'}
      onClick={onDocumentClick}
    >
      <IconContainer>
        {multi ? (
          <CertificateMultiIcon color={theme.palette.primary.main} />
        ) : (
          <CertificateIcon color={theme.palette.primary.main} />
        )}
      </IconContainer>
      <TitleContainer>
        <Title bold={!!document}>{children}</Title>
        {document && (
          <SubTitle>
            {[
              document.issuedDate &&
                `issued: ${formatDateTime(document.issuedDate)}`,
              document.expiryDate &&
                `expires: ${formatDateTime(document.expiryDate)}`
            ]
              .filter(text => text)
              .join(' - ')}
          </SubTitle>
        )}
      </TitleContainer>
      <ChevronRight color="#AFAFAF" size={16} />
    </Container>
  );
};

export default DocumentContainer;
