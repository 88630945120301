import { gql } from '@apollo/client';

const MEMBER_FRAGMENT = gql`
  fragment memberValues on CompanyUser {
    id
    firstName
    lastName
    email
    role
    invitedTimestamp
    isRegistered
    fullName @client
    status @client
    isAdmin @client
  }
`;

export const USER_FRAGMENT = gql`
  fragment userValues on CompanyUser {
    ...memberValues
    onboarding
    latestTermsAccepted
    notifications {
      weeklyRoundup
    }
  }
  ${MEMBER_FRAGMENT}
`;
