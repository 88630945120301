import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  onCancel: () => void;
  onSubmit: ({ reason }: { reason: string }) => void;
  open: boolean;
}

const RejectSubmissionDialog: React.FC<Props> = ({
  onCancel,
  onSubmit,
  open
}: Props) => {
  const [reason, setReason] = useState<string>('');
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReason(e.target.value);
  };
  const onClickReject = () => {
    if (!reason) {
      toast.error('Please add a reason');
      return;
    }
    onSubmit({ reason });
  };

  useEffect(() => {
    setReason('');
  }, [open]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <Box p={3}>
        <Typography variant="h3">
          You are about to reject this impact claim submission
        </Typography>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                fullWidth
                multiline
                label="Please provide feedback for the user"
                rows={4}
                value={reason}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1}>
              <Grid item>
                <Button onClick={onCancel}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  color="error"
                  variant="contained"
                  onClick={onClickReject}
                >
                  Reject
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default RejectSubmissionDialog;
