import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormControl, Grid } from '@mui/material';
import { urlRegex } from 'constants/regex';
import { CertificationCatalogItemMultiLang } from 'types/types.types';
import ImageUploadField from 'components/FormParts/ImageUploadField';

const BasicFields: React.FC = () => {
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext<CertificationCatalogItemMultiLang>();

  const imageUrl: string | undefined = watch('imageUrl');

  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={12}>
        <FormControl fullWidth error={!!errors['imageUrl']}>
          <ImageUploadField
            buttonText="Add Certification Image"
            label="Certification Image"
            {...register('imageUrl', {
              required: true,
              pattern: urlRegex,
            })}
            imageUrl={imageUrl}
            showRequiredError={errors.imageUrl?.type === 'required'}
            targetFolder="defaults/certificate-icons/"
            onAddImage={(url: string) => {
              setValue('imageUrl', url);
            }}
            onRemoveImage={() => {
              setValue('imageUrl', '');
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default BasicFields;
