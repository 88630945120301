import get from 'lodash/get';
import { OrderType } from 'types/types.types';

export const descendingComparator = <T extends Record<string, unknown>>(
  a: T,
  b: T,
  orderBy: keyof T
): number => {
  if (get(b, orderBy) < get(a, orderBy)) {
    return -1;
  }
  if (get(b, orderBy) > get(a, orderBy)) {
    return 1;
  }
  return 0;
};

export const getComparator = <Key extends keyof any>(
  order: OrderType,
  orderBy: Key
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};
