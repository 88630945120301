import React, { useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { ExtendedCompany } from 'types/companies.types';
import ImpactClaimRow from 'components/ImpactClaims/ImpactClaimRow/ImpactClaimRow';
import ProductRow from 'components/Products/ProductRow/ProductRow';
import UserRow from 'components/Users/UserRow';
import { StyledTabPanel } from '../styles';

enum CompanyTabs {
  IMPACT_CLAIMS = 'IMPACT_CLAIMS',
  PRODUCTS = 'PRODUCTS',
  USERS = 'USERS',
}

interface Props {
  company: ExtendedCompany;
}

const CompanyDataTabs: React.FC<Props> = ({ company }) => {
  const [activeTab, setActiveTab] = useState<CompanyTabs>(CompanyTabs.PRODUCTS);
  const handleChange = (event: React.SyntheticEvent, newValue: CompanyTabs) => {
    setActiveTab(newValue);
  };

  return (
    <TabContext value={activeTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleChange}>
          <Tab label="Products" value={CompanyTabs.PRODUCTS} />
          <Tab label="Impact Claims" value={CompanyTabs.IMPACT_CLAIMS} />
          <Tab label="Users" value={CompanyTabs.USERS} />
        </TabList>
      </Box>
      <StyledTabPanel value={CompanyTabs.PRODUCTS}>
        {company.products.length === 0 && (
          <Typography color="secondary" variant="caption">
            No products found...
          </Typography>
        )}

        {company.products.map(product => {
          return (
            <ProductRow
              key={`${product.id}-${product.title}`}
              product={product}
            />
          );
        })}
      </StyledTabPanel>

      <StyledTabPanel value={CompanyTabs.IMPACT_CLAIMS}>
        {company.impactClaims.length === 0 && (
          <Typography color="secondary" variant="caption">
            No impact claims found...
          </Typography>
        )}

        {company.impactClaims.map(impactClaim => {
          return (
            <ImpactClaimRow
              impactClaimSubmission={impactClaim}
              key={`impact-claim-row-${impactClaim.id}`}
            />
          );
        })}
      </StyledTabPanel>

      <StyledTabPanel value={CompanyTabs.USERS}>
        {company.users.length === 0 && (
          <Typography color="secondary" variant="caption">
            No users found...
          </Typography>
        )}

        {company.users.map(user => {
          return <UserRow key={user.id} user={user} />;
        })}
      </StyledTabPanel>
    </TabContext>
  );
};

export default CompanyDataTabs;
