import { FirebaseApp, initializeApp } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/remote-config';

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

class Firebase {
  firebase: FirebaseApp;

  constructor() {
    this.firebase = initializeApp(firebaseConfig);
  }

  getFirebase = () => this.firebase;
}

export default Firebase;
