import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useFirebase } from 'hooks';
import PageContainer from 'components/PageContainer/PageContainer';

const PrivateRoutes: React.FC = () => {
  const { pathname, search } = useLocation();

  const { authenticated } = useFirebase();

  if (authenticated && pathname === '/') {
    return <Navigate to={'/impact-claims/submissions'} />;
  }

  return authenticated ? (
    <PageContainer>
      <Outlet />
    </PageContainer>
  ) : (
    <Navigate state={{ redirect: `${pathname}${search}` }} to={'/login'} />
  );
};

export default PrivateRoutes;
