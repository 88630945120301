import React, { useMemo } from 'react';
import { Alert, AlertTitle, Grid, styled, Typography } from '@mui/material';
import { splitAnalyticsLabelAndValues } from 'utils/analyticsUtils';
import ActivityChart from 'components/Charts/ActivityChart';
import { useCompanyAnalytics } from 'components/Companies/hooks/analytics/useCompanyAnalytics';
import Loader from 'components/Loader/Loader';
import { StyledContainer } from '../styles';
import AnalyticsKeyMetrics from './Metrics/AnalyticsKeyMetrics';

export const ChartWrapper = styled('div')`
  height: 500px;
`;

interface Props {
  companyId: string;
}

const CompanyAnalytics: React.FC<Props> = ({ companyId }) => {
  const { data, error, loading } = useCompanyAnalytics(companyId);

  const {labels} = useMemo(() => {
    return splitAnalyticsLabelAndValues(data?.companyProductsActivity.direct);
  }, [data?.companyProductsActivity]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        An error occured while retrieving analytics data from the server.
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert severity="info">
        <AlertTitle>No Data</AlertTitle>
        No analytics data was found for this company.
      </Alert>
    );
  }

  return (
    <div>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <StyledContainer>
            <Typography gutterBottom fontWeight={700} variant="subtitle1">
              Recent Activity
            </Typography>
            <ChartWrapper>
              <ActivityChart
                labels={labels}
                pageActivity={data.companyProductsActivity}
              />
            </ChartWrapper>

            <AnalyticsKeyMetrics
              analyticsSummary={data.companyAnalyticsSummary}
            />
          </StyledContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyAnalytics;
