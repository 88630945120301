import React, { useMemo } from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Box, Button } from '@mui/material';
import { Check, XLg } from 'styles/icons';
import {
  CertificateCatalogItemStatus,
  CertificationCatalogItemMultiLang,
  MultiLanguageString,
} from 'types/types.types';
import { getAvailableStatusUpdates } from 'utils/certifications';
import { formatDateTime } from 'utils/dates';
import { displayNullableValue } from 'utils/general';
import LabelWithValue from 'components/shared/LabelWithValue/LabelWithValue';
import { ActionButtonWrapper, StatusBox, StyledActionBox } from '../styles';

interface Props {
  activeCertification: CertificationCatalogItemMultiLang<MultiLanguageString>;
  handleUpdateCatalogItemStatus: (
    status: CertificateCatalogItemStatus
  ) => Promise<void>;
  isFormDirty: boolean;
  triggerValidation: UseFormTrigger<
    CertificationCatalogItemMultiLang<MultiLanguageString>
  >;
}

const CertificationStatusBlock: React.FC<Props> = ({
  activeCertification,
  handleUpdateCatalogItemStatus,
  isFormDirty,
  triggerValidation,
}) => {
  const availableStatusUpdates: CertificateCatalogItemStatus[] = useMemo(() => {
    return getAvailableStatusUpdates(activeCertification.status);
  }, [activeCertification.status]);

  return (
    <StyledActionBox>
      <StatusBox>
        <LabelWithValue label="Status">
          <div>{displayNullableValue(activeCertification?.status)}</div>
        </LabelWithValue>
        <LabelWithValue label="Request by">
          <div>
            {displayNullableValue(activeCertification?.requestedByUserEmail)}
          </div>
        </LabelWithValue>
        <LabelWithValue label="Request time">
          <div>
            {activeCertification?.requestedTimestamp
              ? formatDateTime(activeCertification?.requestedTimestamp)
              : '-'}
          </div>
        </LabelWithValue>
      </StatusBox>

      <ActionButtonWrapper>
        {availableStatusUpdates.includes('DENIED') && (
          <Button
            color="error"
            disabled={isFormDirty}
            title={isFormDirty ? 'Save changes first' : 'Deny'}
            variant="contained"
            onClick={() => {
              handleUpdateCatalogItemStatus('DENIED');
            }}
          >
            <Box mr={1}>
              <XLg size={10} />
            </Box>
            Deny
          </Button>
        )}

        {availableStatusUpdates.includes('APPROVED') && (
          <Button
            color="success"
            disabled={isFormDirty}
            title={isFormDirty ? 'Save changes first' : 'Approve'}
            variant="contained"
            onClick={async () => {
              // Validate if all fields are filled in before we can approve the MR
              const isFormValid: boolean = await triggerValidation();

              if (isFormValid) {
                handleUpdateCatalogItemStatus('APPROVED');
              } else {
                toast.error('Please fill in all fields & translations first');
              }
            }}
          >
            <Box mr={1}>
              <Check size={16} />
            </Box>
            Approve
          </Button>
        )}
      </ActionButtonWrapper>
    </StyledActionBox>
  );
};

export default CertificationStatusBlock;
