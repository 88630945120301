import React, { useMemo } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import styled from 'styled-components';
import { splitAnalyticsLabelAndValues } from 'utils/analyticsUtils';
import ActivityChart from 'components/Charts/ActivityChart';
import AnalyticsKeyMetrics from 'components/Companies/ActiveCompanyPage/Analytics/Metrics/AnalyticsKeyMetrics';
import { StyledContainer } from 'components/Companies/ActiveCompanyPage/styles';
import Loader from 'components/Loader/Loader';
import { useOverallAnalytics } from '../hooks/useOverallAnalytics';

export const ChartWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 50vh;
`;

const AnalyticsPage: React.FC = () => {
  const { data, error, loading } = useOverallAnalytics();

  const labels = useMemo(() => {
    if (!data?.allProductsActivity) return [];

    return splitAnalyticsLabelAndValues(data.allProductsActivity.total).labels;
  }, [data?.allProductsActivity]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        An error occurred while retrieving analytics data from the server.
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert severity="info">
        <AlertTitle>No Data</AlertTitle>
        No analytics data was found for this company.
      </Alert>
    );
  }

  return (
    <div>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid item xs={12}>
          <StyledContainer>
            <Typography gutterBottom fontWeight={700} variant="subtitle1">
              Most viewed product
            </Typography>
            <Typography gutterBottom color="red" fontWeight={700} variant="h5">
              {data.allAnalyticsSummary.mostViewedProduct}
            </Typography>
            <Divider />
            <Box mb={2} />
            <Typography gutterBottom fontWeight={700} variant="subtitle1">
              Recent Activity
            </Typography>
            <ChartWrapper>
              <ActivityChart
                labels={labels}
                pageActivity={data.allProductsActivity}
              />
            </ChartWrapper>

            <AnalyticsKeyMetrics analyticsSummary={data.allAnalyticsSummary} />
          </StyledContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default AnalyticsPage;
