import React from 'react';
import { Grid, Tooltip } from '@mui/material';
import { AvailableLanguages } from 'types/companies.types';
import { GreenIndicator, OrangeIndicator } from './styles';

interface CountryFlagProps {
  isPublished: boolean;
  language: AvailableLanguages;
}

/**
 * To get the correct country flag to display in the frontend, we need  to transform languages to country codes.
 * Switches en to gb and ger to de. Needs additional countries if we start supporting more languages.
 * @param language
 * @returns
 */
const languageToCountryCode = (language: AvailableLanguages): string => {
  switch (language) {
    case 'en':
      return 'gb';
    case 'de':
      return 'de';
    case 'fr':
      return 'fr';
    case 'nl':
      return 'nl';
    case 'es':
      return 'es';
    default:
      return language;
  }
};

const CountryFlag: React.FC<CountryFlagProps> = ({ isPublished, language }) => {
  return (
    <Tooltip
      key={`language-flag-${language}`}
      title={isPublished ? 'Published' : 'Draft'}
    >
      <Grid item>
        <img
          alt={`${language} flag`}
          height="10"
          key={language}
          loading="lazy"
          src={`https://flagcdn.com/${languageToCountryCode(language)}.svg`}
          width="20"
        />
        {isPublished ? <GreenIndicator /> : <OrangeIndicator />}
      </Grid>
    </Tooltip>
  );
};

export default CountryFlag;
