import React from 'react';
import { Box, Switch, Typography } from '@mui/material';
import styled from 'styled-components';

interface Props {
  checked: boolean;
  onClick?: () => void;
  subtitle: string;
  title: string;
}

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(2)};
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
`;

export const SwitchWrapper = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
`;

const SwitchBox: React.FC<Props> = ({ checked, onClick, subtitle, title }) => {
  return (
    <Wrapper onClick={onClick}>
      <Box>
        <Typography variant="body2">{title}</Typography>
        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </Box>
      <SwitchWrapper>
        <Switch checked={checked} size="small" />
      </SwitchWrapper>
    </Wrapper>
  );
};

export default SwitchBox;
