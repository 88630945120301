import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { ImpactClaimLog } from 'types/types.types';
import { formatDate } from 'utils/dates';
import { getStatusTitle } from 'utils/ImpactClaims';
import { ClaimLogDate, ColoredDot } from './styles';

interface Props {
  logItem: ImpactClaimLog;
}

const ClaimHistoryItem: React.FC<Props> = ({ logItem }: Props) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      py={1}
    >
      <Box alignItems="center" display="flex">
        <Tooltip title={`${logItem.user?.firstName} ${logItem.user?.lastName}`}>
          <ColoredDot status={logItem.action} />
        </Tooltip>
        <Box>
          <Typography variant="body2">
            {getStatusTitle(logItem.action)}
          </Typography>
          <Typography variant="caption">{logItem.user?.email}</Typography>
        </Box>
      </Box>
      <ClaimLogDate>{formatDate(logItem.timestamp)}</ClaimLogDate>
    </Box>
  );
};

export default ClaimHistoryItem;
