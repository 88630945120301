import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { EMPTY_CLAIM } from 'constants/ImpactClaims';
import { ImpactCatalogItemMultiLang } from 'types/types.types';
import BasicFields from 'components/ImpactClaims/ActiveImpactClaim/BasicFields/BasicFields';
import MultiLangFields from 'components/ImpactClaims/ActiveImpactClaim/MultiLangFields/MultiLangFields';
import { ButtonContainer, FieldsContainer, StyledForm } from './styles';

interface Props {
  creatingNewClaim: boolean;
  initialImpactClaim?: ImpactCatalogItemMultiLang;
  onSubmit: SubmitHandler<ImpactCatalogItemMultiLang>;
}

const ImpactClaimForm: React.FC<Props> = ({
  creatingNewClaim,
  initialImpactClaim,
  onSubmit,
}: Props) => {
  const defaultValues = useMemo(
    () =>
      !creatingNewClaim && initialImpactClaim
        ? initialImpactClaim
        : EMPTY_CLAIM,
    [creatingNewClaim, initialImpactClaim]
  );
  const methods = useForm<ImpactCatalogItemMultiLang>({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FieldsContainer>
              <BasicFields initialImpactClaim={defaultValues} />
            </FieldsContainer>
          </Grid>

          <Grid item xs={12}>
            <FieldsContainer>
              <MultiLangFields initialImpactClaim={defaultValues} />
            </FieldsContainer>
          </Grid>
        </Grid>

        <ButtonContainer>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => methods.reset()}
          >
            Discard
          </Button>
          <Button
            color="secondary"
            size="large"
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </ButtonContainer>
      </StyledForm>
    </FormProvider>
  );
};

export default ImpactClaimForm;
