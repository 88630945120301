import React, { createContext } from 'react';
import Config, { ConfigStatics } from './Config';
import config from './config.json';

interface ContextProps {
  config: Config;
}

interface ProviderProps {
  children: React.ReactChild;
}

type Environments = 'dev' | 'stg' | 'prd';
const configStatics: { [environment in Environments]: ConfigStatics } = config;

const defaultConfig = configStatics.dev;
const environment: Environments =
  (process.env.REACT_APP_DEPLOYMENT_ENV as Environments) || 'dev';
const environmentConfig = configStatics[environment];
const finalConfig = { ...defaultConfig, ...environmentConfig };

const ConfigContext = createContext<ContextProps>({ config: new Config() });
const ConfigConsumer = ConfigContext.Consumer;
const configInstance = new Config(finalConfig);

const ConfigProvider = ({ children }: ProviderProps) => (
  <ConfigContext.Provider value={{ config: configInstance }}>
    {children}
  </ConfigContext.Provider>
);

export { ConfigConsumer, configInstance, ConfigProvider };

export default ConfigContext;
