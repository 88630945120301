import React from 'react';
import { Background, Spinner } from './styles';

interface Props {
  includeBackground?: boolean;
}

export const Loader: React.FC<Props> = ({
  includeBackground
}: Props): JSX.Element => {
  if (includeBackground) {
    return (
      <Background>
        <Spinner />
      </Background>
    );
  }

  return <Spinner />;
};

export default Loader;
