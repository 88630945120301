export const EMPTY_MULTI_LANG_FIELD = [
  {
    language: 'en',
    value: ''
  },
  {
    language: 'de',
    value: ''
  },
  {
    language: 'fr',
    value: ''
  },
  {
    language: 'nl',
    value: ''
  },
  {
    language: 'es',
    value: ''
  }
];
