import {
  IRawMaterialLogAction,
  IRawMaterialMultiLang,
} from 'types/rawMaterials.types';
import { EMPTY_MULTI_LANG_FIELD } from './Language';

export const EMPTY_RAW_MATERIAL: IRawMaterialMultiLang = {
  id: '',
  title: EMPTY_MULTI_LANG_FIELD,
  createdTimestamp: '',
  logs: [],
  latestLog: {
    id: '',
    rawMaterialId: '',
    userId: '',
    action: 'CREATED' as IRawMaterialLogAction,
    createdTimestamp: '',
  },
  latestLogAction: 'CREATED' as IRawMaterialLogAction,
};
