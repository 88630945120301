import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_ANALYTICS } from 'graphql/queries/analytics';
import { ProductAnalyticsResult } from 'types/analytics.types';
import { getDateMonthsFromNow } from 'utils/dates';

export const useProductAnalytics = (productId: string, embedded: boolean) => {
  // Always start the data 3 months from now.
  const startDate: string = useMemo(() => {
    return getDateMonthsFromNow(3);
  }, []);

  return useQuery<ProductAnalyticsResult>(GET_PRODUCT_ANALYTICS, {
    onError: () => {
      toast('Something went wrong while fetching the product analytics data.');
    },
    variables: {
      params: {
        productId,
        startDate,
        embedded,
      },
    },
  });
};
