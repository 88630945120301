import React, { Fragment, useMemo, useState } from 'react';
import { produce } from 'immer';
import { ChevronDown, ChevronUp, ThreeDotsVertical } from 'styles/icons';
import { ImpactClaimLog } from 'types/types.types';
import ClaimHistoryItem from 'components/ImpactClaims/ActiveSubmission/ClaimHistory/ClaimHistoryItem';
import {
  ClaimHistoryContainer,
  DotsBefore,
  MiddleHistory,
  MinimizeButton,
  SeeHistoryButton,
} from './styles';

interface Props {
  history: ImpactClaimLog[];
}

const ClaimHistory: React.FC<Props> = ({ history }: Props) => {
  const [showMiddleLog, setShowMiddelLog] = useState<boolean>(false);
  const toggleShowMiddleLog = () => setShowMiddelLog(curr => !curr);

  const middleHistory = useMemo(() => {
    return produce(history, draft => {
      draft.shift();
      draft.pop();
      return draft;
    });
  }, [history]);

  if (!history.length) return null;

  return (
    <Fragment>
      <ClaimHistoryContainer>
        <ClaimHistoryItem logItem={history[0]} />

        {middleHistory.length > 0 ? (
          <Fragment>
            <MiddleHistory open={showMiddleLog || middleHistory.length === 1}>
              {middleHistory.map(logItem => (
                <ClaimHistoryItem key={logItem.timestamp} logItem={logItem} />
              ))}
            </MiddleHistory>
            {!showMiddleLog && middleHistory.length !== 1 && (
              <SeeHistoryButton onClick={toggleShowMiddleLog}>
                <DotsBefore>
                  <ThreeDotsVertical size={20} />
                </DotsBefore>
                <ChevronDown size={16} />
                See all history
              </SeeHistoryButton>
            )}
          </Fragment>
        ) : null}

        {history.length > 1 && (
          <ClaimHistoryItem logItem={history[history.length - 1]} />
        )}
      </ClaimHistoryContainer>
      {middleHistory.length > 0 && showMiddleLog && (
        <MinimizeButton onClick={toggleShowMiddleLog}>
          <ChevronUp size={16} />
          Minimize
        </MinimizeButton>
      )}
    </Fragment>
  );
};

export default ClaimHistory;
