import { FC } from 'react';
import { useControl } from 'react-map-gl';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';

const DeckGLOverlay: FC<
  MapboxOverlayProps & {
    interleaved?: boolean;
  }
> = props => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export default DeckGLOverlay;
