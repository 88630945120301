import styled from 'styled-components';
import { ImpactClaimStatusType } from 'types/types.types';

export const ClaimHistoryContainer = styled.div`
  position: relative;
  max-width: 500px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    width: 2px;
    left: 5px;
    top: 20px;
    bottom: 20px;
    background: ${({ theme }) => theme.palette.background.default};
  }
`;
export const MiddleHistory = styled.div<{ open: boolean }>`
  transition: all 0.4s ease;
  max-height: ${({ open }) => (open ? 5000 : 0)}px;
  opacity: ${({ open }) => (open ? 1 : 0)};
  overflow: hidden;
`;

export const SeeHistoryButton = styled.button`
  background: none;
  color: ${({ theme }) => theme.palette.grey[400]};
  border: none;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  padding: ${({ theme }) => theme.spacing(1.5, 1, 1.5, 0)};
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const MinimizeButton = styled.button`
  background: none;
  color: ${({ theme }) => theme.palette.grey[400]};
  border: none;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  padding: ${({ theme }) => theme.spacing(2, 1, 2, 2)};
  cursor: pointer;
  text-align: center;
  width: 100%;

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ColoredDot = styled.div<{
  status: ImpactClaimStatusType;
}>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${({ status, theme }) => theme.statusColor[status]};
  margin-right: ${({ theme }) => theme.spacing(1)};
  z-index: 1;
`;

export const ClaimLogDate = styled.div`
  color: ${({ theme }) => theme.palette.grey[400]};
  font-size: 13px;
`;

export const DotsBefore = styled.div`
  background: #fff;
  margin-left: -4px;
  padding: 3px 0;
  z-index: 1;
  color: ${({ theme }) => theme.palette.background.default};
`;
