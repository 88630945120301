import React, { useEffect, useState } from 'react';
import Firebase from 'contexts/Firebase';
import { getAuth } from 'firebase/auth';
import Loader from 'components/Loader/Loader';

interface FirebaseContextProps {
  authenticated: boolean;
  firebase: Firebase | null;
}

const FirebaseContext = React.createContext<FirebaseContextProps>({
  authenticated: false,
  firebase: null
});
const FirebaseConsumer = FirebaseContext.Consumer;

interface Props {
  children: React.ReactChild;
  firebase: Firebase;
}

const FirebaseProvider = ({ children, firebase: firebaseProp }: Props) => {
  const firebase = firebaseProp || new Firebase();

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged(user => {
      setAuthenticated(!!user);
      setLoading(false);
    });
  }, []); //eslint-disable-line

  if (loading) return <Loader />;

  return (
    <FirebaseContext.Provider value={{ firebase, authenticated }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { FirebaseConsumer, FirebaseProvider };

export default FirebaseContext;
