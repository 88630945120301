import { gql } from '@apollo/client';
import { ADDRESS_FRAGMENT } from 'graphql/fragments/address';
import { IMAGE_VARIANT_FRAGMENT } from 'graphql/fragments/image';

export const COMPANY_FIELDS_FRAGMENT = gql`
  fragment companyFields on Company {
    id
    name
    onlineShop
    website
    customerType
    createdTimestamp
    invitedByCompany {
      id
      name
    }
  }
`;

export const COMPANY_FEATURES_FRAGMENT = gql`
  fragment companyFeatures on CompanyFeature {
    feedback
    certificates
    whiteLabeling
    verifiedPayments
    payments
    compliance
  }
`;

export const COMPANY_PLAN_DETAILS_FRAGMENT = gql`
  fragment companyPlanDetails on CustomerPlan {
    name
    productLimit
    userLimit
    upgradeRequested
    expired
    expirationDate
    planType
  }
`;

export const COMPANY_DESIGN_FRAGMENT = gql`
  fragment companyDesign on CompanyDesign {
    defaultBorderRadius
    buttonBorderRadius
    typography {
      header
      body
    }
    palette {
      primary
      secondary
    }
  }
`;
export const COMPANY_USER_FRAGMENT = gql`
  fragment user on CompanyUser {
    firstName
    lastName
    email
    role
    invitedBy
    onboarding
    latestTermsAccepted
    notifications {
      weeklyRoundup
    }
  }
`;

export const COMPANY_MEMBER_FRAGMENT = gql`
  fragment user on CompanyUser {
    firstName
    lastName
    email
    role
    invitedBy
  }
`;

export const COMPANY_FRAGMENT = gql`
  fragment companyAllFields on Company {
    ...companyFields
    logo {
      ...imageVariant
    }
    address {
      ...address
    }
    features {
      ...companyFeatures
    }
    planDetails {
      ...companyPlanDetails
    }
    design {
      ...companyDesign
    }
    dataValid
  }
  ${COMPANY_FIELDS_FRAGMENT}
  ${COMPANY_FEATURES_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
  ${COMPANY_PLAN_DETAILS_FRAGMENT}
  ${COMPANY_DESIGN_FRAGMENT}
`;

export const ADMIN_COMPANY_FRAGMENT = gql`
  fragment adminCompanyValues on Company {
    ...companyFields
    logo {
      ...imageVariant
    }
    address {
      ...address
    }
    features {
      ...companyFeatures
    }
    planDetails {
      name
      productLimit
      userLimit
      upgradeRequested
      expirationDate
      expired
      planType
    }
  }
  ${COMPANY_FIELDS_FRAGMENT}
  ${COMPANY_FEATURES_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${IMAGE_VARIANT_FRAGMENT}
`;
