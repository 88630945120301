import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { ActivityAnalytics } from 'types/analytics.types';
import { splitAnalyticsLabelAndValues } from 'utils/analyticsUtils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ArcElement
);

const CHART_OPTIONS: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxis: {
      min: 0,
    },
  },
};

interface Props {
  labels: string[];
  pageActivity: ActivityAnalytics;
}

const ActivityChart: React.FC<Props> = ({ labels, pageActivity }) => {
  const totalVisits = useMemo(() => {
    return splitAnalyticsLabelAndValues(pageActivity.total);
  }, [pageActivity.total]);

  const directVisits = useMemo(() => {
    return splitAnalyticsLabelAndValues(pageActivity.direct);
  }, [pageActivity.direct]);

  const QrVisits = useMemo(() => {
    return splitAnalyticsLabelAndValues(pageActivity.qr);
  }, [pageActivity.qr]);

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            fill: true,
            label: 'Total Visits',
            data: totalVisits.values,
            backgroundColor: 'rgba(255, 183, 1, 0.5)',
            borderColor: 'rgba(255, 183, 1, 0.7)',
          },
          {
            fill: false,
            label: 'Direct Visits',
            data: directVisits.values,
            backgroundColor: 'rgba(12, 25, 59, 0.5)',
            borderColor: 'rgba(12, 25, 59, 0.7)',
          },
          {
            fill: true,
            label: 'QR Visits',
            data: QrVisits.values,
            backgroundColor: 'rgba(48, 167, 58, 0.5)',
            borderColor: 'rgba(48, 167, 58, 0.7)',
          },
        ],
      }}
      options={CHART_OPTIONS}
    />
  );
};

export default ActivityChart;
