import { isValid, subMonths } from 'date-fns';
import format from 'date-fns/format';

export const formatDateTime = (date: string | number | Date): string => {
  const dateTimeFormat = 'dd MMM yyyy - HH:mm';
  const dateInCorrectFormat = typeof date === 'string' ? new Date(date) : date;
  return format(dateInCorrectFormat, dateTimeFormat);
};

export const formatDate = (date: string | number | Date): string => {
  const dateTimeFormat = 'MMMM dd, yyyy';
  const dateInCorrectFormat = typeof date === 'string' ? new Date(date) : date;
  return format(dateInCorrectFormat, dateTimeFormat);
};

export const formatDateInBackendFormat = (date: string | number | Date) => {
  const dateTimeFormat = 'yyyy-LL-dd';
  if (!isValid(date)) return String(date);
  const dateInCorrectFormat = typeof date === 'string' ? new Date(date) : date;
  return format(dateInCorrectFormat, dateTimeFormat);
};

export const getDateMonthsFromNow = (months: number) => {
  return subMonths(new Date(), months).toISOString();
};

const makeTimeDisplayValue = (time: number, indicator: string) => {
  if (time === 0) {
    return null;
  }

  return `${time}${indicator}`;
};

export const formatMilisecondsToReadableTime = (duration: number): string => {
  if (duration === 0) {
    return '-';
  }

  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const seconds = Math.floor((duration / 1000) % 60);

  const displayHours: string | null = makeTimeDisplayValue(hours, 'h');
  const displayMinutes: string | null = makeTimeDisplayValue(minutes, 'm');
  const displaySeconds: string | null = makeTimeDisplayValue(seconds, 's');

  return `${displayHours ? displayHours : ''} ${
    displayMinutes ? displayMinutes : ''
  } ${displaySeconds ? displaySeconds : ''}`;
};
