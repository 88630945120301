import React, { useMemo, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { Product } from 'types/companies.types';
import { splitAnalyticsLabelAndValues } from 'utils/analyticsUtils';
import ActivityChart from 'components/Charts/ActivityChart';
import DeviceChart from 'components/Charts/DeviceChart';
import { useProductAnalytics } from 'components/Companies/hooks/analytics/useProductAnalytics';
import Loader from 'components/Loader/Loader';
import ProductListItem from 'components/Products/ProductListItem/ProductListItem';
import SwitchBox from 'components/shared/SwitchBox/SwitchBox';
import { StyledContainer } from '../styles';
import AnalyticsMap from './AnalyticsMap/AnalyticsMap';
import AnalyticsKeyMetrics from './Metrics/AnalyticsKeyMetrics';

export const ChartWrapper = styled('div')`
  height: 600px;
`;

interface Props {
  products: Product[];
}

const ProductAnalytics: React.FC<Props> = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(products[0]?.id);
  const [isEmbeddedSelected, setIsEmbeddedSelected] = useState(false);

  const { data, error, loading } = useProductAnalytics(
    selectedProduct,
    isEmbeddedSelected
  );

  const { labels } = useMemo(() => {
    return splitAnalyticsLabelAndValues(data?.pageActivity.total);
  }, [data?.pageActivity.total]);

  if (loading) {
    return <Loader />;
  }

  if (error || !selectedProduct) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        An error occured while retrieving analytics data from the server.
      </Alert>
    );
  }

  if (!data) {
    return (
      <Alert severity="info">
        <AlertTitle>No Data</AlertTitle>
        No analytics data was found for this product.
      </Alert>
    );
  }

  return (
    <div>
      <Grid container alignItems="stretch" spacing={3}>
        <Grid item xs={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledContainer>
                <Typography gutterBottom fontWeight={700} variant="subtitle1">
                  Recent Activity
                </Typography>

                <ChartWrapper>
                  <ActivityChart
                    labels={labels}
                    pageActivity={data.pageActivity}
                  />
                </ChartWrapper>

                <AnalyticsKeyMetrics
                  analyticsSummary={data.productAnalyticsSummary}
                />
              </StyledContainer>
            </Grid>

            <Grid item maxHeight={400} xs={6}>
              <StyledContainer>
                <Typography gutterBottom fontWeight={700} variant="subtitle1">
                  Visits by device
                </Typography>

                <DeviceChart
                  deviceInfo={data.deviceInfo}
                  maxWidth={300}
                  totalVisits={data.productAnalyticsSummary.views.current}
                />
              </StyledContainer>
            </Grid>

            <Grid item maxHeight={400} xs={6}>
              <StyledContainer>
                <Typography gutterBottom fontWeight={700} variant="subtitle1">
                  Visits by Location
                </Typography>

                <AnalyticsMap geoAnalytics={data.geoAnalytics} />
              </StyledContainer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Box mb={3}>
            <SwitchBox
              checked={isEmbeddedSelected}
              subtitle="Show analytics for the embedded widgets"
              title="Show Embedded"
              onClick={() => {
                setIsEmbeddedSelected(!isEmbeddedSelected);
              }}
            />
          </Box>
          <Stack spacing={1}>
            <Typography fontWeight={700} variant="body2">
              Company Products
            </Typography>
            <Typography gutterBottom variant="caption">
              {"Select a product to see it's analytics data"}
            </Typography>
            {products.map(product => {
              return (
                <ProductListItem
                  isSelected={selectedProduct === product.id}
                  key={`product-list-item-${product.id}`}
                  product={product}
                  onClick={() => setSelectedProduct(product.id)}
                />
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductAnalytics;
