import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ClaimCategory from 'components/ImpactClaims/ClaimCategory/ClaimCategory';
import ClaimDefinition from 'components/ImpactClaims/ClaimDefinition/ClaimDefinition';
import useSingleSubmission from 'components/ImpactClaims/hooks/useSingleSubmission';
import SdgPills from 'components/SdgPill/SdgPill';
import CheckList from 'components/shared/CheckList/CheckList';
import { Container } from './styles';

const ClaimDetails: React.FC = () => {
  const { submissionId } = useParams();
  const { claimSubmission } = useSingleSubmission({ id: submissionId });

  if (!claimSubmission) return null;

  return (
    <Container paddingX={3} paddingY={2}>
      <h2>{claimSubmission?.impactCatalogItem.title}</h2>

      <Box justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" my={2}>
          <Typography variant="h3">Impact definition</Typography>

          <ClaimCategory
            categoryType={claimSubmission.impactCatalogItem.category.type}
          />
        </Box>
      </Box>
      <ClaimDefinition impactClaim={claimSubmission} />

      <Box my={3}>
        <Typography variant="h3">Criteria</Typography>
        <CheckList
          items={
            claimSubmission.impactCatalogItem.acceptanceCriteria?.map(
              criteria => criteria.description
            ) || []
          }
        />
      </Box>
      <Box my={3}>
        <Typography variant="h3">Document Criteria</Typography>
        <CheckList
          items={
            claimSubmission.impactCatalogItem.docRequirements?.map(
              criteria => criteria.description
            ) || []
          }
        />
      </Box>
      <Box my={3}>
        <Typography variant="h3">Connected global goals</Typography>
        <SdgPills
          sdgs={claimSubmission?.impactCatalogItem.sustainableDevGoals || []}
        />
      </Box>
    </Container>
  );
};

export default ClaimDetails;
