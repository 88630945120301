import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, TableCell } from '@mui/material';
import get from 'lodash/get';
import {
  CertificateCatalogItemStatus,
  CertificationCatalogItemMultiLang,
} from 'types/types.types';
import { getEnglishValue } from 'utils/translations';
import useCertifications from 'components/Certifications/hooks/useCertifications';
import Table, { HeadCell } from 'components/Table/Table';

const headCells: HeadCell<
  CertificationCatalogItemMultiLang<string | undefined>
>[] = [
  {
    id: 'imageUrl',
    label: '',
  },
  {
    id: 'title',
    label: 'Certification',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

interface Props {
  activeCertificationStatuses: CertificateCatalogItemStatus[];
}
const ICON_SIZE = 35;

const MangeCertificationsPage: React.FC<Props> = ({
  activeCertificationStatuses,
}) => {
  const { certifications, handleCertificationClick } = useCertifications({
    activeCertificationStatuses: activeCertificationStatuses,
  });
  const navigate = useNavigate();

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          onClick={() => navigate(`/certifications/new`)}
        >
          Add new
        </Button>
      </Box>
      <Table<CertificationCatalogItemMultiLang<string | undefined>>
        defaultSorting="title"
        headCells={headCells}
        rows={certifications.map(value => {
          return {
            ...value,
            title: getEnglishValue(value.title),
          };
        })}
        onRowClick={handleCertificationClick}
      >
        {row => (
          <Fragment>
            {headCells.map(({ id }) => {
              if (id === 'imageUrl') {
                return (
                  <TableCell key={id} size="small" sx={{ width: '60px' }}>
                    <Avatar
                      src={row.imageUrl}
                      sx={{ width: ICON_SIZE, height: ICON_SIZE }}
                    />
                  </TableCell>
                );
              }
              return <TableCell key={id}>{get(row, id)}</TableCell>;
            })}
          </Fragment>
        )}
      </Table>
    </Box>
  );
};

export default MangeCertificationsPage;
