import React, { FC } from 'react';
import { BrowserRouter, Route, Routes as Router } from 'react-router-dom';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { IRawMaterialLogAction } from 'types/rawMaterials.types';
import { QueryParamProvider } from 'use-query-params';
import AnalyticsPage from 'components/Analytics/CompaniesPage/AnalyticsPage';
import Login from 'components/Auth/Login/Login';
import PrivateRoutes from 'components/Auth/PrivateRoutes/PrivateRoutes';
import ActiveCertificationCatalogItem from 'components/Certifications/ActiveCertificationCatalogItem/ActiveCertificationCatalogItem';
import ManageCertificationsPage from 'components/Certifications/ManageCertificationsPage/ManageCertificationsPage';
import ActiveCompanyPage from 'components/Companies/ActiveCompanyPage/ActiveCompanyPage';
import CompaniesPage from 'components/Companies/CompaniesPage/CompaniesPage';
import ActiveImpactClaim from 'components/ImpactClaims/ActiveImpactClaim/ActiveImpactClaim';
import ActiveSubmission from 'components/ImpactClaims/ActiveSubmission/ActiveSubmission';
import ImpactClaimsPage from 'components/ImpactClaims/ImpactClaimsPage/ImpactClaimsPage';
import SubmissionsPage from 'components/ImpactClaims/SubmissionsPage/SubmissionsPage';
import ActiveRawMaterial from 'components/RawMaterials/ActiveRawMaterial/ActiveRawMaterial';
import ManageRawMaterialsPage from 'components/RawMaterials/ManageRawMaterialsPage';

const Routes: FC = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <Router>
          <Route element={<Login />} path="/login" />
          <Route element={<PrivateRoutes />} path="/">
            <Route path="impact-claims">
              <Route element={<ImpactClaimsPage />} path="" />
              <Route element={<ActiveImpactClaim />} path=":impactClaimId" />

              <Route path="submissions">
                <Route element={<SubmissionsPage />} path="" />
                <Route element={<ActiveSubmission />} path="new" />
                <Route element={<ActiveSubmission />} path=":submissionId" />
              </Route>
            </Route>

            <Route path="companies">
              <Route element={<CompaniesPage />} path=""></Route>
              <Route element={<ActiveCompanyPage />} path=":companyId"></Route>
            </Route>

            <Route element={<AnalyticsPage />} path="analytics" />

            <Route path="certifications">
              <Route
                element={
                  <ManageCertificationsPage
                    activeCertificationStatuses={['APPROVED']}
                  />
                }
                path=""
              />
              <Route
                element={
                  <ManageCertificationsPage
                    activeCertificationStatuses={['REQUESTED', 'DENIED']}
                  />
                }
                path="requests"
              />
              <Route
                element={<ActiveCertificationCatalogItem />}
                path=":certificationId"
              />
            </Route>
            <Route path="raw-materials">
              <Route
                element={
                  <ManageRawMaterialsPage
                    activeRawMaterialStatuses={[
                      'APPROVED' as IRawMaterialLogAction
                    ]}
                  />
                }
                path=""
              />
              <Route
                element={
                  <ManageRawMaterialsPage
                    activeRawMaterialStatuses={[
                      'REQUESTED' as IRawMaterialLogAction,
                      'CREATED' as IRawMaterialLogAction,
                      'DENIED' as IRawMaterialLogAction
                    ]}
                  />
                }
                path="requests"
              />
              <Route element={<ActiveRawMaterial />} path=":rawMaterialId" />
            </Route>
          </Route>
        </Router>
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default Routes;
