import React, { FC, useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { CompanyUser } from 'types/companies.types';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import { formatDateTime } from 'utils/dates';
import { CompanyAttributeRow } from 'components/Companies/ActiveCompanyPage/styles';

interface Props {
  user: CompanyUser;
}

const UserRow: FC<Props> = ({ user }) => {
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <CompanyAttributeRow>
      <Grid container alignItems="center">
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={2}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">Username</Typography>
            <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
          </Box>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={2}>
          <Box display="flex" flexDirection="column" maxWidth="100%">
            <Typography variant="caption">ID</Typography>
            <Tooltip
              leaveDelay={400}
              open={copied}
              title="Copied user ID to clipboard"
              onClose={() => setCopied(false)}
            >
              <Box
                style={{
                  cursor: 'pointer',
                  maxWidth: 'calc(100% - 30px)'
                }}
                onClick={() => {
                  setCopied(true);
                  copyTextToClipboard(user.id);
                }}
              >
                <Typography
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {user.id}
                </Typography>
              </Box>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">Email</Typography>
            <Typography>{user.email ? user.email : '-'}</Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="caption">Last Active</Typography>
            <Typography>
              {user.latestActivityTimestamp
                ? formatDateTime(user.latestActivityTimestamp)
                : '-'}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </CompanyAttributeRow>
  );
};

export default UserRow;
