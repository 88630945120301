import styled from 'styled-components';

export const StyledActionBox = styled('div')`
  background: #fff;
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(3)};
  display: inline-flex;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  flex-direction: column;
`;

export const StatusBox = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ActionButtonWrapper = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
`;
