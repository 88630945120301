import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_CERTIFICATION_CATALOG_ITEM,
  DELETE_CERTIFICATION_CATALOG_ITEMS,
  UPDATE_CERTIFICATION_CATALOG_ITEM,
} from 'graphql/mutations';
import { GET_CERTIFICATION_CATALOG_ITEMS } from 'graphql/queries';
import { UpdateCertificationCatalogPayload } from 'types/mutations.types';
import {
  CertificateCatalogItemStatus,
  CertificationCatalogItemMultiLang,
  CertificationCatalogResult,
} from 'types/types.types';

interface Props {
  activeCertificationId?: string;
  activeCertificationStatuses?: CertificateCatalogItemStatus[];
}

const useCertifications = ({
  activeCertificationId,
  activeCertificationStatuses,
}: Props = {}) => {
  const {
    data: { certificateCatalogItems: { edges: certifications = [] } = {} } = {},
    error,
    loading,
    refetch,
  } = useQuery<CertificationCatalogResult>(GET_CERTIFICATION_CATALOG_ITEMS, {
    fetchPolicy: activeCertificationId ? 'cache-first' : 'cache-and-network',
  });

  const navigate = useNavigate();

  const [createCertificationCatalogItem] = useMutation(
    CREATE_CERTIFICATION_CATALOG_ITEM,
    {
      onCompleted: () => {
        toast.success('Certification added successfully');
        navigate(`/certifications`);
        refetch();
      },
      onError: () => {
        toast.error('Something went wrong while adding certification');
      },
    }
  );

  const [updateCertificationCatalogItem] =
    useMutation<UpdateCertificationCatalogPayload>(
      UPDATE_CERTIFICATION_CATALOG_ITEM,
      {
        onCompleted: data => {
          const { status } = data.updateCertificateCatalogItem.item;

          if (status === 'APPROVED') {
            navigate(`/certifications`);
          } else {
            navigate(`/certifications/requests`);
          }

          toast.success('Certification updated successfully');
        },
        onError: () => {
          toast.error('Something went wrong while updating certification');
        },
      }
    );

  const [deleteCertificationCatalogItem] = useMutation(
    DELETE_CERTIFICATION_CATALOG_ITEMS,
    {
      onCompleted: () => {
        toast.success('Certification deleted successfully');
      },
      onError: () => {
        toast.error('Something went wrong while deleting certification');
      },
    }
  );

  const handleDeleteCatalogItem = async (id: string) => {
    await deleteCertificationCatalogItem({
      variables: {
        ids: [id],
      },
    });
    navigate(`/certifications`);
  };

  const handleUpdateCatalogItem = async (
    values: CertificationCatalogItemMultiLang
  ) => {
    await updateCertificationCatalogItem({
      variables: {
        id: activeCertificationId,
        input: {
          title: values.title,
          description: values.description,
          imageUrl: values.imageUrl,
          status: values.status,
        },
      },
    });
  };

  const handleCreateCatalogItem = async (
    values: CertificationCatalogItemMultiLang
  ) => {
    await createCertificationCatalogItem({
      variables: {
        input: {
          title: values.title,
          description: values.description,
          imageUrl: values.imageUrl,
        },
      },
    });
  };

  const activeCertification = useMemo(() => {
    return certifications.find(({ node }) => node.id === activeCertificationId)
      ?.node;
  }, [activeCertificationId, certifications]);

  const handleUpdateCatalogItemStatus = async (
    status: CertificateCatalogItemStatus
  ) => {
    if (activeCertification) {
      await handleUpdateCatalogItem({ ...activeCertification, status });
    } else {
      toast.error(
        'Something went wrong while updating the certification status'
      );
    }
  };

  const handleCertificationClick = (certificationId: string) => {
    navigate(`/certifications/${certificationId}`);
  };

  return {
    certifications: certifications
      .map(({ node }) => node)
      .filter(
        ({ status }) =>
          !activeCertificationStatuses ||
          (status && activeCertificationStatuses.includes(status))
      ),
    error: error,
    loading: loading,
    handleCreateCatalogItem,
    refetch,
    activeCertification,
    handleCertificationClick,
    handleUpdateCatalogItem,
    handleDeleteCatalogItem,
    handleUpdateCatalogItemStatus,
  };
};

export default useCertifications;
